import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { TableContainer, TableHead, Table, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { mapHealthDataTypeNumToStr } from '../../../utils/conversions';
import { IOP, VISUAL_ACUITY } from '../../../constants/medicalRecords';
import FadeIn from 'react-fade-in';
import { getValuesGenerateKeys, transferValueGetKey } from '../../../utils/helpers';
import { useSelector } from 'react-redux';

const PREFIX = 'HealthDataTable';

const classes = {
  table: `${PREFIX}-table`,
  tableContainer: `${PREFIX}-tableContainer`,
  mainTableHead: `${PREFIX}-mainTableHead`,
  extraTableHead: `${PREFIX}-extraTableHead`,
  background: `${PREFIX}-background`,
  rightEye: `${PREFIX}-rightEye`,
  leftEye: `${PREFIX}-leftEye`,
  recordDate: `${PREFIX}-recordDate`,
  type: `${PREFIX}-type`,
};

const StyledFadeIn = styled(FadeIn)(({ theme, props }) => ({
  [`& .${classes.table}`]: {
    minWidth: 300,
  },

  [`& .${classes.tableContainer}`]: {
    maxHeight: 320,
  },

  [`& .${classes.mainTableHead}`]: {
    zIndex: '5',
  },

  [`& .${classes.extraTableHead}`]: {
    backgroundColor: theme.palette.background.default,
    position: 'sticky',
    top: '0',
    zIndex: '10',
    fontSize: '11pt',
  },

  [`& .${classes.background}`]: {
    backgroundColor: theme.palette.background.default,
  },
  [`& .${classes.rightEye}`]: {
    width: '20%',
    paddingRight: 0,
  },
  [`& .${classes.leftEye}`]: {
    width: '20%',
    paddingRight: 0,
  },
  [`& .${classes.recordDate}`]: {
    width: '20%',
    paddingRight: 0,
    paddingLeft: '5px',
  },
  [`& .${classes.type}`]: {
    paddingRight: 0,
  },
}));

const HealthDataTable = (props) => {
  const staticWords = useSelector((state) => state.handlingTranslation.words);
  return (
    <StyledFadeIn>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader aria-label="health data table">
          <TableHead>
            <TableRow>
              <TableCell className={(classes.mainTableHead, classes.type)}>{staticWords.Type}</TableCell>
              <TableCell align="center" className={classes.rightEye}>
                {staticWords.Right_Eye}
              </TableCell>
              <TableCell align="center" className={classes.leftEye}>
                {staticWords.Left_Eye}
              </TableCell>
              <TableCell align="center" className={(classes.mainTableHead, classes.recordDate)}>
                {staticWords.Recorded_Date}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.healthData.map((row, i) => {
              if (parseInt(row[0]) === VISUAL_ACUITY) return null; // ignore deprecated VISUAL_ACUITY
              const healthDataType = mapHealthDataTypeNumToStr(row[0]);
              const recordedDate = row[1].recorded_date && row[1].recorded_date.slice(0, 10);
              const hasLeftValues = (row[1].values?.Left && row[1].values.Left) || undefined;
              const renderValues = () => {
                if (hasLeftValues) {
                  const rightValue = row[1].values.Right;
                  const leftValue = row[1].values.Left;

                  return (
                    <>
                      <TableCell align="center">{rightValue}</TableCell>
                      <TableCell align="center">{leftValue}</TableCell>
                    </>
                  );
                } else {
                  return (
                    <TableCell align="center" colSpan={2}>
                      {row[1].values}
                    </TableCell>
                  );
                }
              };

              const renderTableSubheader = () => {
                if (parseInt(row[0]) === IOP) {
                  return (
                    <>
                      <TableRow style={{ height: props.extraHeaderHeight + 'px' }}>
                        <TableCell className={classes.background}></TableCell>
                        <TableCell align="center" className={classes.extraTableHead} colSpan={2}>
                          {staticWords.Data}
                        </TableCell>
                        <TableCell className={classes.background}></TableCell>
                      </TableRow>
                    </>
                  );
                }
                return null;
              };

              return (
                <React.Fragment key={healthDataType + i}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {staticWords[getValuesGenerateKeys(healthDataType)]}
                    </TableCell>
                    {renderValues()}
                    <TableCell align="center">{recordedDate}</TableCell>
                  </TableRow>
                  {renderTableSubheader()}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledFadeIn>
  );
};

HealthDataTable.propTypes = {
  healthData: PropTypes.array.isRequired,
};

export default HealthDataTable;
