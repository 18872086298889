import React from 'react';
import { connect } from 'react-redux';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import LargeButton from '../atoms/LargeButton';
import IconHeading from '../molecules/IconHeading';
import { handleModalClose } from '../../../actions/controls';
import { ArrowRightIcon } from '../../../resources/icons';

import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../styles/theme';

const PREFIX = 'Modal';

const classes = {
  form: `${PREFIX}-form`,
  centerDialogActions: `${PREFIX}-centerDialogActions`,
};

const Root = styled('div', {
  shouldForwardProp(propName) {
    return !['centerDialog'].includes(propName);
  },
})(({ theme, centerDialog }) => ({
  [`& .${classes.centerDialogActions}`]: {
    justifyContent: centerDialog && 'center',
  },
}));

const Modal = (props) => {
  const {
    modalName,
    title,
    icon,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonType = 'primary',
    secondaryButtonType = 'secondary',
    primaryButtonIcon = <ArrowRightIcon />,
    onPrimaryClick,
    onSecondaryClick,
    showCloseButton,
    sx,
    centerDialog,
    maxWidth,
    noButtonIcon,
    headingStyle,
    openedModals,
    handleModalClose,
    disableOnClose,
    customWidth,
    dialogBoxPadding,
    dialogBoxMargin,
    noMargin,
  } = props;

  const handlePrimaryClick = (e) => {
    e.preventDefault();
    onPrimaryClick(e);
    handleModalClose(modalName);
  };

  const handleSecondaryClick = (e) => {
    e.preventDefault();
    onSecondaryClick && onSecondaryClick(e);
    handleModalClose(modalName);
  };

  return (
    <Root centerDialog={centerDialog}>
      <Dialog
        maxWidth={maxWidth}
        open={openedModals.includes(modalName)}
        onClose={disableOnClose ? null : () => handleModalClose(modalName)}
        aria-labelledby="form-dialog-title"
        sx={sx}
      >
        <form style={{ margin: theme.spacing(2) }} onSubmit={handlePrimaryClick}>
          <DialogTitle>
            <IconHeading title={title} icon={icon} style_2={headingStyle} />
            {showCloseButton ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => handleModalClose(modalName)}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            {props.description && (
              <DialogContentText className={props.descriptionSpacing}>{props.description}</DialogContentText>
            )}
            {props.children}
          </DialogContent>
          <DialogActions
            className={classes.centerDialogActions}
            sx={{ paddingBottom: dialogBoxPadding, marginInline: dialogBoxMargin }}
          >
            <Grid container spacing={2}>
              {secondaryButtonText && (
                <Grid item sm={primaryButtonText ? 6 : 12}>
                  <LargeButton
                    buttonType={secondaryButtonType}
                    fullWidth
                    noMargin={noMargin}
                    onClick={handleSecondaryClick}
                    customWidth={customWidth}
                  >
                    {secondaryButtonText}
                  </LargeButton>
                </Grid>
              )}
              {primaryButtonText && (
                <Grid item sm={secondaryButtonText ? 6 : 12}>
                  <LargeButton
                    buttonType={primaryButtonType}
                    color="blue"
                    type="submit"
                    noMargin={noMargin}
                    endIcon={!noButtonIcon && primaryButtonIcon}
                    fullWidth
                    onClick={handlePrimaryClick}
                    customWidth={customWidth}
                  >
                    {primaryButtonText}
                  </LargeButton>
                </Grid>
              )}
            </Grid>
          </DialogActions>
          {props.bottomMessage && (
            <Box
              paddingX={2}
              paddingBottom={2}
              marginTop={-1}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Typography variant="subtitle1" color="warning.dark">
                {props.bottomMessage}
              </Typography>
            </Box>
          )}
        </form>
      </Dialog>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  openedModals: state.controls.modalOpen,
});

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  primaryButtonType: PropTypes.string,
  secondaryButtonType: PropTypes.string,
  headingStyle: PropTypes.any,
  icon: PropTypes.element.isRequired,
  openedModals: PropTypes.array.isRequired,
  modalName: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  descriptionSpacing: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default connect(mapStateToProps, { handleModalClose })(Modal);
