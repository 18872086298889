export const enMissingTranslation = {
  en: {
    Admin: 'Admin',
    GP: 'GP',
    MARKETING: 'MARKETING',
    Successfully_logged_in_: 'Successfully logged in!',
    Processing: 'Processing',
    Canceled: 'Canceled',
    QA_not_assigned_to_image_: 'QA not assigned to image.',
    Thank_you_for_verifying_your: 'Thank you for verifying your',
    Please_enter_your_e_mail_: 'Please enter your e-mail.',
    Assigned_reviews: 'Assigned reviews',
    resubmit: 'resubmit',
    Cases_that_must_be_referred_to_a_reviewer_in_a_week_or_earlier__as_recommended_in_the_eye_screening_audit_:
      'Cases that must be referred to a reviewer in a week or earlier, as recommended in the eye screening audit.',
    Ref_No_: 'Ref No.',
    medicare_reference_NO_: 'medicare reference No.',
    Invalid_Phone_Number: 'Invalid Phone Number',
    PENDING: 'PENDING',
    NOT_ADDED: 'NOT ADDED',
    Comment: 'Comment',
    Send_Report_Link: 'Send Report Link',
    Nikon: 'Nikon',
    Topcon: 'Topcon',
    DRS: 'DRS',
    TeleEye_Scan: 'TeleEye Scan',
    Upload_and_select_images_to_be_sent_for_grading__with_several:
      'Upload and select images to be sent for grading with several',
    Pending_for_image_upload: 'Pending for image upload',
    Waiting_for_results_from_services: 'Waiting for results from services',
    Pending_for_eye_professional_review: 'Pending for eye professional review',
    Report_ready_to_download: 'Report ready to download',
    Service_hasn_t_been_selected: "Service hasn't been selected",
    _Add_on_screenings_will_be_valid_for_3_months__If_you_upgrade_your_subscriptions__your_add_on_screenings_will_be_removed_:
      '*Add-on screenings will be valid for 3 months. If you upgrade your subscriptions, your add-on screenings will be removed.',
    Add_free_credits_to_individual_accounts__users_that_are_not_a_part_of_any_organisations__below_:
      'Add free credits to individual accounts (users that are not a part of any organisations) below.',
    Please_wait: 'Please wait',
    before_trying_again: 'before trying again',
    Email_last_sent_: 'Email last sent:',
    SMS_last_sent: 'SMS last sent:',
    American_Academy_of_Ophthalmology__AAO__Standard: 'American Academy of Ophthalmology (AAO) Standard',
    Findings_Observable_upon_Dilated_Ophthalmoscopy: 'Findings Observable upon Dilated Ophthalmoscopy',
    Severity_Level: 'Severity Level',
    International_Clinical_Diabetic_Macular_Edema_Disease_Severity_Scale:
      'International Clinical Diabetic Macular Edema Disease Severity Scale',
    International_Clinical_Diabetic_Retinopathy_Disease_Severity_Scale:
      'International Clinical Diabetic Retinopathy Disease Severity Scale',
    No_abnormalities: 'No abnormalities',
    Microaneurysms_only: 'Microaneurysms only',
    More_than_just_microaneurysms_but_less_than_Severe_NPDR: 'More than just microaneurysms but less than Severe NPDR',
    Any_of_the_following: 'Any of the following',
    More_than_20_intraretinal_hemorrhages_in_each_of_4_quadrants:
      'More than 20 intraretinal hemorrhages in each of 4 quadrants',
    Definite_venous_beading_in_2__quadrants: 'Definite venous beading in 2+ quadrants',
    Prominent_IRMA_in_1__quadrant: 'Prominent IRMA in 1+ quadrant',
    And_no_signs_of_proliferative_retinopathy: 'And no signs of proliferative retinopathy',
    One_or_more_of_the_following: 'One or more of the following',
    No_retinal_thickening_or_hard_exudates_in_posterior_pole:
      'No retinal thickening or hard exudates in posterior pole',
    Some_retinal_thickening_or_hard_exudates_in_posterior_pole_but_distant_from_the_center_of_the_macula:
      'Some retinal thickening or hard exudates in posterior pole but distant from the center of the macula',
    Retinal_thickening_or_hard_exudates_approaching_the_center_of_the_macula_but_not_involving_the_center:
      'Retinal thickening or hard exudates approaching the center of the macula but not involving the center',
    Retinal_thickening_or_hard_exudates_involving_the_center_of_the_macula:
      'Retinal thickening or hard exudates involving the center of the macula',
    If_your_account_is_created_by_your_organisation_s_admin__you_are_able_to_log_in_now_Otherwise__you_would_need_to_wait_for_our_verification_and_approval_before_you_can_log_in_An_email_will_be_sent_to_you_once_your_account_has_been_approved:
      "If your account is created by your organisation's admin, you are able to log in now.Otherwise, you would need to wait for our verification and approval before you can log in.An email will be sent to you once your account has been approved.",
    Create_Password: 'Create Password',
    Diseases_Summary: 'Diseases Summary',
    check_OTP_usage_for_: 'check OTP usage for:',
    You_will_be_joining: 'You will be joining',
    Next_billed_on: 'Next billed on',
    Diabetic__Retinopathy: 'Diabetic\n Retinopathy',
    Type_or_paste_email_addresses_and_press: 'Type or paste email addresses and press',
    ENTER: 'ENTER',
    has_already_been_added_: 'has already been added.',
    is_not_a_valid_email_address_: 'is not a valid email address.',
    Comments_for_practitioners_and_reviewers__This_will_not_appear_on_the_patient_s_exam_report:
      'Comments for practitioners and reviewers. This will not appear on the patient’s exam report',
    Upload_up_to: 'Upload up to',
    images: 'images',
    per_eye__for_grading__ensuring_they_meet_the: 'per eye) for grading, ensuring they meet the',
    one: 'one',
    two: 'two',
    four: 'four',
    specified_requirements: 'specified requirements',
    No_image_upload_limit_found_for_the_region: 'No image upload limit found for the region',
    Please_upload_images_to_continue: 'Please upload images to continue',
    image: 'image',
    per_eye_is_allowed_for_submission: 'per eye is allowed for submission',
    Only_a_maximum_of: 'Only a maximum of',
    You_can_only_upload_a_maximum_of: 'You can only upload a maximum of',
    Manage_Subscriptions: 'Manage Subscriptions',
    You_have_successfully_subscribed_to: 'You have successfully subscribed to',
    You_can_manage_your_subscriptions_here_: 'You can manage your subscriptions here.',
    Only: 'Only',
    Diabetic_Retinopathy__DR__Severity: 'Diabetic Retinopathy (DR) Severity',
    Diabetic_Retinopathy__DR__: 'Diabetic Retinopathy (DR)',
    Site_Language: 'Site Language',
    By_default__Mild_NPDR_is_Non_Referable__Select_Referable_if_necessary_:
      'By default, Mild NPDR is Non-Referable. Select Referable if necessary.',
    Prac_: 'Prac.',
    Patient: 'Patient',
    OPTOM: 'OPTOM',
    Rev_: 'Rev.',
    Org_Ad: 'Org Ad',
    TMC_Ad: 'TMC Ad',
    Review_Date: 'Review Date',
    Patient_Account_No_: 'Patient Account No.',
    Successfully_added_new_data_of: 'Successfully added new data of',
    Successfully_updated_medical_history_: 'Successfully updated medical history.',
    No_matching_patients_records_found_: 'No matching patients records found.',
    Month: 'Month',
    Year: 'Year',
    Invoice: 'Invoice',
    Date: 'Date',
    Total_Amount: 'Total_Amount',
    Email_is_required: 'Email is required',
    Invalid_Email_Address: 'Invalid Email Address',
    Required: 'Required',
    Cardholder_Name: 'Cardholder Name',
    Credit: 'Credit',
    Bank: 'Bank',
    Try_again: 'Try again',
    Qty: 'Qty',
    Exam_Plans: 'Exam Plans',
    Billing: 'Billing',
    Purchase_Summary: 'Purchase Summary',
    Glaucoma_and_AMD_indications_are_solely_based_on_independent_Healthcare_Provider_review_:
      'Glaucoma and AMD indications are solely based on independent Healthcare Provider review.',
    Diabetic_Macular_Edema_and_Media_Opacity_indications_are_solely_based_on_independent_Healthcare_Provider_review_:
      'Diabetic Macular Edema and Media Opacity indications are solely based on independent Healthcare Provider review.',
    Clinical_investigational_use_only: 'Clinical investigational use only',
    Disclaimer: 'Disclaimer',
    The_clinician_must_review_the_AI_results_below__make_an_independent_clinical_assessment__and_adjust_the_results_accordingly_:
      'The clinician must review the AI results below, make an independent clinical assessment, and adjust the results accordingly.',
    The_final_clinical_assessment_was_completed_by_a_clinician_and_is_not_reliant_upon_any_AI_:
      'The final clinical assessment was completed by a clinician and is not reliant upon any AI.',
    Monthly_Subscription: 'Monthly Subscription',
    Prepaid: 'Prepaid',
    Payment_Success: 'Payment Success',
    Payment_Unsuccessful: 'Payment Unsuccessful',
    Neovascularization: 'Neovascularization',
    Vitreous_preretinal_hemorrhage: 'Vitreous/preretinal hemorrhage',
    Diabetic_Retinopathy_detection_Nonreferable_Referable_result:
      'Diabetic Retinopathy detection (Non-referable / Referable result)',
    Exam_Report_provided_to_patient_by_Health_Care_Professional:
      'Exam Report provided to patient by Health Care Professional',
    Diabetic_Retinopathy_Glaucoma_and_AMD_detection_Nonreferable_Referable_result:
      'Diabetic Retinopathy, Glaucoma and AMD detection (Non-referable / Referable result)',
    Diabetic_Retinopathy_Severity_Grading: 'Diabetic Retinopathy Severity Grading',
    Facility_for_Eye_Health_Practitioner_to_review_and_verify_results:
      'Facility for Eye Health Practitioner to review and verify results',
    CHOOSE_AN_AI_PLAN: 'CHOOSE AN AI PLAN',
    PURCHASE_EXAMS: 'PURCHASE EXAMS',
    Onetime_payment_valid_6_months: 'One-time payment, valid 6 months',
    End_of_month_billing_cycle: 'End of month billing cycle',
    Exams: 'Exams',
    month: 'month',
    Please_select_an_AI_Plan_above_before_continuing: 'Please select an AI Plan above before continuing.',
    Choose_your_preferred_prepaid_amount: 'Choose your preferred prepaid amount:',

    Choose_your_preferred_monthly_plan: 'Choose your preferred monthly plan*:',
    _A_minimum_subscription_period_of_3_months_is_required_for_first_time_purchase:
      '* A minimum subscription period of 3 months is required for first time purchase.',
    PURCHASE: 'PURCHASE',
    Monthly_Subscription_2_Exams_150month_GST: 'Monthly Subscription* 2 Exams - $150/month + GST',
    Monthly_Subscription_2_Exams: 'Monthly Subscription - 2 Exams',
    GST: 'GST',
    Total_onetime_payment: 'Total (one-time payment)',
    CONTINUE_TO_PAYMENT: 'CONTINUE TO PAYMENT',
    UPDATE_SUBSCRIPTION: 'UPDATE SUBSCRIPTION',
    OFF: 'OFF',
    Prepaid_2_Exams_4500_GST: 'Prepaid 2 Exams - $4500 + GST',
    Prepaid_2_Exams: 'Prepaid - 2 Exams',
    Total_per_month: 'Total (per month)',
    Go_to: 'Go to',
    Your_payment_was_not_successful_This_may_be_due_to_one_of_the_following_reasons:
      'Your payment was not successful. This may be due to one of the following reasons:',
    Insufficient_funds: 'Insufficient funds',
    Expired_card: 'Expired card',
    Payment_verification_failure: 'Payment verification failure',
    Network_Issues: 'Network Issues',
    TRY_AGAIN: 'TRY AGAIN',
    Payment_Successful: 'Payment Successful',
    Prepaid_250_Exams_2375_GST: 'Prepaid 250 Exams - $2375 + GST',
    OKAY: 'OKAY',
    UNPAID: 'UNPAID',
    PAID: 'PAID',
    OVERDUE: 'OVERDUE',
    CANCELLED: 'CANCELLED',
    CURRENT_EXAM_PLANS: 'CURRENT EXAM PLAN(S)',
    Manage_Subscription: 'Manage Subscription',
    ACTIVE: 'ACTIVE',
    Exam_Balance: 'Exam Balance',
    Purchase_Prepaid: 'Purchase Prepaid',
    Total_Exam_Balance: 'Total Exam Balance',
    No_active_monthly_subscriptions_plans_found: 'No active monthly subscriptions plans found.',
    No_prepaid_plans_found: 'No prepaid plans found.',
    PAYMENT_FAILED: 'PAYMENT FAILED',
    DEACTIVATED: 'DEACTIVATED',
    Incomplete: 'Incomplete',
    Well_retry_payment_every_5_days_Please_update_your_billing_information_here:
      '(We’ll retry payment every 5 days. Please update your billing information here.)',
    Your_subscription_is_currently_deactivated_due_to_a_failed_payment:
      'Your subscription is currently deactivated due to a failed payment',
    FREE_TRIAL: 'FREE TRIAL',
    NO_PURCHASE_HISTORY: 'NO PURCHASE HISTORY',
    You_do_not_have_any_purchase_history_to_display_Once_you_complete_a_transaction_it_will_appear_here:
      'You do not have any purchase history to display. Once you complete a transaction, it will appear here.',
    Purchase_Exam_Plans: 'Purchase Exam Plans',
    YOUR_AI_PLAN: 'YOUR AI PLAN',
    mth: 'mth',
    INCOMPLETE: 'INCOMPLETE',
    PAUSED: 'PAUSED',
    INCOMPLETE_EXPIRED: 'INCOMPLETE EXPIRED',
    DRAFT: 'DRAFT',
    Your_last_transaction_failed__Please_review_and_complete_the_payment:
      'Your last transaction failed. Please review and complete the payment',
    __For_help__contact: '. For help, contact',
    Invoice_No: 'Invoice No.',
    Invoice_Date: 'Invoice Date',
    Exam_Plan: 'Exam Plan',
    Amount: 'Amount',
    Monthly: 'Monthly',
    Download: 'Download',
    Pay_now: 'Pay now',
    Okay: 'Okay',
    we_will_retry_payment_every_5_days_: '(we will retry payment every 5 days)',
    here: 'here',
    This_link_will_remain_active_for_30_minutes__After_that__the_transaction_will_be_canceled__and_a_new_purchase_will_need_to_be_initiated_:
      'This link will remain active for 30 minutes. After that, the transaction will be canceled, and a new purchase will need to be initiated.',
    Billing_history_is_not_displayed_in_test_mode_: 'Billing history is not displayed in test mode.',

    Your_payment_for_Monthly_Subscription_has_been_successful__You_can_view_your_invoices_in:
      'Your payment for Monthly Subscription has been successful. You can view your invoices in',
    Plans_are_not_displayed_in_test_mode_: 'Plans are not displayed in test mode.',
    There_was_a_problem_of_exam_plans__Please_try_again___: 'There was a problem of exam plans. Please try again...',
    Your_subscription_has_been_canceled__please_contact_us_at:
      'Your subscription has been canceled, please contact us at',
    First_bill_: 'First bill:',
    on: 'on',
    Expires: 'Expires:',
    Price_listed_is_in: 'Price listed is in ',
    _and_does_not_include_GST_Applicable_taxes_will_be_added:
      ' and does not include GST. Applicable taxes will be added.',
    Purchased_on_: 'Purchased on:',
    Next_bill_: 'Next bill:',
    Your_current_monthly_subscription_plan_is_active_If_you_would_like_to_make_any_changes_to_your_subscription_please_contact_us_at:
      'Your current monthly subscription plan is active. If you would like to make any changes to your subscription, please contact us at',
    Your_monthly_subscription_free_trial_is_active_If_you_would_like_to_make_any_changes_to_your_subscription_please_contact_us_at:
      'Your monthly subscription free trial is active. If you would like to make any changes to your subscription, please contact us at',
    Trial_ends_: 'Trial ends',
    Your_subscription_will_be_deactivated_if_it_is_not_paid_before:
      'Your subscription will be deactivated if it is not paid before',
    Please_try_again_If_the_problem_persists_contact_us_on: 'Please try again. If the problem persists, contact us on',
    for_further_assistance: 'for further assistance.',
    Your_payment_for_Prepaid_Exams_has_been_successful_You_can_view_your_invoices_in:
      'Your payment for Prepaid Exams has been successful. You can view your invoices in',

    About: 'About',
    DR_Grader_and__GCAM_Instructions_of_Use__IFU__Manuals_: 'DR Grader and +GCAM Instructions of Use (IFU) Manuals:',
    View_IFUs: 'View IFUs',
    Name_of_Devices: 'Name of Devices',
    _GCAM: '+GCAM',
    Intended_Purpose: 'Intended Purpose',
    DRS01: 'DRS01',
    GCS01: 'GCS01',
    DR_Grader_is_a_medical_device: 'DR Grader is a medical device',
    _GCAM_is_a_medical_device: '+GCAM is a medical device',
    _01_860007668609_8012__v1_0_1: '(01)860007668609(8012) v1.0.1',
    _01_860007668647_8012__v1_0_1: '(01)860007668647(8012) v1.0.1',
    IFU_is_translated_to_German: 'IFU is translated to German',
    Manufacturer: 'Manufacturer',
    Date_of_Manufacturer_: 'Date of Manufacturer:',
    Country_of_Manufacture_: 'Country of Manufacture:',
    The_DR_Grading_software_is_intended:
      'The DR Grading software is intended to screen and grade Diabetic Retinopathy (DR) in patients/general population through colour fundus retinal images. The DR Grader is indicated for use by health care providers (HCP) to automatically detect and grade diabetic retinopathy in patients who have been diagnosed with diabetes and screen those who have not yet been diagnosed with diabetes.',
    _GCAM_is_software_as_a_medical_device:
      '+GCAM is software as a medical device intended as an aid to a healthcare provider’s (HCP) decision-making in the screening fundus images for probable Glaucoma and Age-related Macular Degeneration (AMD). The software will provide a referable / non-referable recommendation based on the signs detected. The screening result is not intended to be a confirmatory diagnosis or to be definitive in nature. The screening allows for further investigation by the healthcare provider.',
    Sherwood_Road_Toowong_: 'Suite 5/31 Sherwood Road, Toowong,',
    Brisbane_Queensland_: 'Brisbane, Queensland',
    __Australia: 'Australia',
    Erdkampsweg_43_22335_Hamburg: 'Erdkampsweg 43;22335 Hamburg',
    Phone___61_416220239: 'Phone: +61 416220239',
    Are_you_sure_you_want_to_submit_review__The_eye_exam_review_is_not_editable_after_submission_:
      'Are you sure you want to submit review? The eye exam review is not editable after submission.',
    Are_you_sure_you_want_to_submit_exam__The_eye_exam_is_not_editable_after_submission_:
      'Are you sure you want to submit exam? The eye exam is not editable after submission.',

    Your_total_completed_reviews: 'Your total completed reviews:',
    Your_Saved_Review: 'Your Saved Review',
    Delete: 'Delete',
    Continue: 'Continue',
    No_completed_reviews: 'No completed reviews',
    Review_currently_unavailable: 'Review currently unavailable',
    Please_refresh_the_page_and_try_again_: 'Please refresh the page and try again!',
    The_eye_exam_results_must_be_reviewed_by_an_eye_health_professional_:
      'The eye exam results must be reviewed by an eye health professional.',
    Please_review_the_exam_now_or_check_back_later_for_updates_:
      'Please review the exam now or check back later for updates.',
    Save_Progress___Exit: 'Save Progress & Exit',
    Exit: 'Exit',
    Save_your_Review_Progress_: 'Save your Review Progress?',
    Your_progress_will_be_saved_for_24_hours_: 'Your progress will be saved for 24 hours.',
    If_the_review_is_not_completed_within_this_timeframe__your_progress_will_be_deleted__and_the_exam_will_be_returned_to_the_pool_of_pending_reviews_:
      'If the review is not completed within this timeframe, your progress will be deleted, and the exam will be returned to the pool of pending reviews.',
    Back: 'Back',
    Save___Exit: 'Save & Exit',
    Exit_without_Saving_Progress_: 'Exit without Saving Progress?',
    Your_review_progress_will_be_deleted__and_the_exam_will_be_returned_to_the_pool_of_pending_reviews_:
      'Your review progress will be deleted, and the exam will be returned to the pool of pending reviews.',
    Delete_Progress: 'Delete Progress',
    Information: 'Information',
    Reviewer_Comment: 'Reviewer Comment',
    Saved_Review_not_Completed: 'Saved Review not Completed',
    Please_complete_your_saved_review_exam_before_starting_another_exam_:
      'Please complete your saved review exam before starting another exam.',
    Delete_Saved_Review_: 'Delete Saved Review?',
    Public: 'Public',
    Private: 'Private',
    public: 'Public',
    private: 'Private',
    Reviews: 'Reviews',
    Germany: 'Germany',
    New_Zealand: 'New Zealand',
    Singapore: 'Singapore',
    Ireland: 'Ireland',
    United_Kingdom: 'United Kingdom',
    Malaysia: 'Malaysia',
    India: 'India',
    Age_related_Macular_Degeneration_will_only_be_graded_for_users_who_have_age_older_than_45_:
      'Age-related Macular Degeneration will only be graded for users who have age older than 45.',
    CDR_image_unavailable: 'CDR image unavailable',
    CDR_Image_removed: 'CDR Image removed',
    Undo: 'Undo',
    Remove_image: 'Remove image',
    Patient_Type: 'Patient Type',

    // ----------------------------( 08-01-2025 ) New Words ( component: AcceptInvitation.js )-------------------------------------//
    Hey: 'Hey',
    is_inviting_you_to_join_their_organisation: 'is inviting you to join their organisation',
    What_will: 'What will',
    be_able_to_View_: 'be able to View?',
    Your_Profile_Details: 'Your Profile Details',
    You_will_be_using: 'You will be using',
    s_subscription: "'s subscription.",
    You_will_be_able_to_view_all_of: 'You will be able to view all of',
    s_patients: "'s patients",
    You_will_lose_access_to: 'You will lose access to',
    You_will_lose_access_to_all: 'You will lose access to all',
    // ----------------------------( 08-01-2025 ) New Words ( file: exam.js )-------------------------------------//
    Submitting_images___: 'Submitting images...',
    Successfully_Sent_Report_to: 'Successfully Sent Report to',
    There_was_a_problem_sending_report_to: 'There was a problem sending report to',
    __Please_try_again_: '. Please try again.',
    There_was_a_problem_retrieving_report_send_status__Please_try_again_:
      'There was a problem retrieving report send status. Please try again.',
    Successfully_Downloaded_Report: 'Successfully Downloaded Report',
    There_was_a_problem_downloading_report__Please_try_again_:
      'There was a problem downloading report. Please try again.',
    // ----------------------------( 09-01-2025 ) New Words ( component: Header.js )-------------------------------------//
    We_Use_Cookies: 'We Use Cookies',
    Our_website_uses_cookies_to_ensure_essential_functionality_and_to_improve_your_experience__You_can_choose_to_allow_only_necessary_cookies_or_accept_all_cookies_for_an_enhanced_experience_:
      'Our website uses cookies to ensure essential functionality and to improve your experience. You can choose to allow only necessary cookies or accept all cookies for an enhanced experience.',
    ACCEPT_ALL_COOKIES: 'ACCEPT ALL COOKIES',

    NECESSARY_COOKIES_ONLY: 'NECESSARY COOKIES ONLY',
    DELETE: 'DELETE',
    CANCEL: 'CANCEL',

    // ----------------------------( 16-01-2025 ) New Words ( component: HeaderDataTable.js )-------------------------------------//
    Diet: 'Diet',
    Insulin: 'Insulin',
    Medication: 'Medication',
    On_Diet: 'On Diet',
    On_Medication: 'On Medication',
    On_Insulin: 'On Insulin',
    Diet_Insulin_Medication: 'Diet, Insulin, Medication',
  },
};

export const enUiVisibleTexts = {
  en: {
    ...enMissingTranslation.en,
    Lost_connection_to_server__please_refresh_the_page: 'Lost connection to server, please refresh the page',
    Unable_to_load_pooled_reviews__please_try_again_by_refreshing_the_page_:
      'Unable to load pooled reviews, please try again by refreshing the page.',
    There_was_a_problem_retrieving_the_audit__Please_contact_us_if_this_persists_:
      'There was a problem retrieving the audit. Please contact us if this persists.',
    There_was_a_problem_submitting_the_audit__Please_contact_us_if_this_persists_:
      'There was a problem submitting the audit. Please contact us if this persists.',
    There_was_a_problem_assigning_the_review__Please_try_to_review_other_exams:
      'There was a problem assigning the review. Please try to review other exams',
    There_was_a_problem_logging_in__Please_try_again_later_: 'There was a problem logging in. Please try again later.',
    The_session_is_already_ended_: 'The session is already ended.',
    You_have_successfully_signed_up_: 'You have successfully signed up.',
    There_was_a_problem_signing_up__Please_try_again_later_: 'There was a problem signing up. Please try again later.',
    No_email_or_phone_number_provided: 'No email or phone number provided',
    There_was_a_problem_checking_the_account__Please_try_again_later_:
      'There was a problem checking the account. Please try again later.',
    Successfully_created_: 'Successfully created!',
    There_was_a_problem_when_retrieving_Terms_and_Conditions_data__Please_refresh_to_try_again_:
      'There was a problem when retrieving Terms and Conditions data. Please refresh to try again.',
    Thank_you_for_accepting_the_updated_terms_of_use_: 'Thank you for accepting the updated terms of use.',
    'There_was_a_problem_when_accepting_Terms_and_Conditions__Please_contact_us_if_it_persists`_':
      'There was a problem when accepting Terms and Conditions. Please contact us if it persists`.',
    Invitation_Link_Copied__Valid_for_24_hours_: 'Invitation Link Copied! Valid for 24 hours.',
    There_was_a_problem_joining_this_organisation__please_contact_your_organisation_admin_:
      'There was a problem joining this organisation, please contact your organisation admin.',
    Email_Verified_: 'Email Verified!',
    Email_or_Mobile_Number_is_required: 'Email or Mobile Number is required',
    There_was_a_problem_creating_an_exam__Please_try_again_later_:
      'There was a problem creating an exam. Please try again later.',
    There_was_a_problem_retrieving_exams__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving exams. Please refresh the page to try again.',
    There_was_a_problem_retrieving_page_number_for_exams__please_try_again_:
      'There was a problem retrieving page number for exams, please try again.',
    Uploading_image: 'Uploading image',
    Successfully_uploaded: 'Successfully uploaded',
    There_is_a_problem_with_uploading__Please_refresh_page_and_try_again_:
      'There is a problem with uploading. Please refresh page and try again.',
    There_was_a_problem_deleting_image__Please_try_again_later_:
      'There was a problem deleting image. Please try again later.',
    disconnected_websocket_for_exam: 'disconnected websocket for exam',
    There_was_a_problem_retrieving_an_image_s_information__Please_try_again_later_:
      "There was a problem retrieving an image's information. Please try again later.",
    There_was_a_problem_retrieving_the_order_details_: 'There was a problem retrieving the order details.',
    There_was_a_problem_when_getting_exam_details__Please_try_to_refresh_the_page_:
      'There was a problem when getting exam details. Please try to refresh the page.',
    There_was_a_problem_retrieving_urgent_exams__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving urgent exams. Please refresh the page to try again.',
    Please_select_at_least_one_image_to_submit_: 'Please select at least one image to submit.',
    Image_s__submitted_for_grading: 'Image(s) submitted for grading',
    There_was_a_problem_when_submitting_images__Please_try_again_:
      'There was a problem when submitting images. Please try again.',
    There_was_a_problem_assigning_the_laterality_for_the_image__please_try_again_:
      'There was a problem assigning the laterality for the image, please try again.',
    No_services_selected_: 'No services selected.',
    Successfully_submitted_: 'Successfully submitted!',
    There_was_a_problem_submitting_for_services__Please_try_again_later_:
      'There was a problem submitting for services. Please try again later.',
    Successfully_resubmitted_: 'Successfully resubmitted!',
    There_was_a_problem_when_resubmitting__Please_try_again_:
      'There was a problem when resubmitting. Please try again.',
    There_was_a_problem_when_submitting_comments__Please_try_again_:
      'There was a problem when submitting comments. Please try again.',
    Loading_report___: 'Loading report...',
    Report_successfully_loaded_: 'Report successfully loaded!',
    There_was_an_error_retrieving_auditors__Please_try_again_:
      'There was an error retrieving auditors. Please try again.',
    There_was_an_error_retrieving_health_data__Please_refresh_the_page_to_try_again_:
      'There was an error retrieving health data. Please refresh the page to try again.',
    There_is_a_problem_when_adding_new_health_data__Please_try_again_later_:
      'There is a problem when adding new health data. Please try again later.',
    There_is_a_problem_when_updating_medical_history__Please_try_again_later_:
      'There is a problem when updating medical history. Please try again later.',
    There_was_a_problem_loading_users__Please_refresh_the_page_to_try_again_:
      'There was a problem loading users. Please refresh the page to try again.',
    There_was_a_problem_loading_organisations__Please_refresh_the_page_to_try_again_:
      'There was a problem loading organisations. Please refresh the page to try again.',
    There_was_a_problem_adding_this_organisation__Please_try_again_later_:
      'There was a problem adding this organisation. Please try again later.',
    Successfully_approved_: 'Successfully approved.',
    There_was_a_problem_approving_this_user__Please_try_again_later_:
      'There was a problem approving this user. Please try again later.',
    Successfully_added_free_credits_: 'Successfully added free credits.',
    There_was_a_problem_adding_free_credits__Please_try_again_later_:
      'There was a problem adding free credits. Please try again later.',
    There_was_a_problem_retrieving_meeting_hosts__Please_try_again_:
      'There was a problem retrieving meeting hosts. Please try again.',
    There_was_a_problem_retrieving_your_contact_information__Please_try_to_refresh_page_:
      'There was a problem retrieving your contact information. Please try to refresh page.',
    There_was_a_problem_updating_your_contact_information__Please_try_again_:
      'There was a problem updating your contact information. Please try again.',
    failed_to_update_contact_information: 'failed to update contact information',
    There_was_a_problem_retrieving_meetings__Please_try_again_:
      'There was a problem retrieving meetings. Please try again.',
    There_was_a_problem_retrieving_history_meetings__Please_try_again_:
      'There was a problem retrieving history meetings. Please try again.',
    There_was_a_problem_retrieving_available_slots__Please_try_again_:
      'There was a problem retrieving available slots. Please try again.',
    There_was_a_problem_retrieving_provider_s_first_available_date__Please_try_again_:
      "There was a problem retrieving provider's first available date. Please try again.",
    There_was_a_problem_retrieving_provider_schedule__Please_try_again_:
      'There was a problem retrieving provider schedule. Please try again.',
    Meeting_successfully_canceled: 'Meeting successfully canceled',
    There_was_a_problem_when_connecting_to_server__Please_refresh_page_and_try_again:
      'There was a problem when connecting to server. Please refresh page and try again',
    There_was_a_problem_when_canceling_meeting__Please_try_again_:
      'There was a problem when canceling meeting. Please try again.',
    The_time_is_not_available__please_choose_another_time: 'The time is not available, please choose another time',
    There_was_a_problem_when_fetch_new_meeting_details__Please_try_again_:
      'There was a problem when fetch new meeting details. Please try again.',
    There_was_a_problem_when_booking_meeting__Please_try_again_:
      'There was a problem when booking meeting. Please try again.',
    Successfully_rescheduled_meeting: 'Successfully rescheduled meeting',
    There_was_a_problem_when_rescheduling_meeting__Please_try_again_:
      'There was a problem when rescheduling meeting. Please try again.',
    Your_comment_has_been_successfully_submitted_: 'Your comment has been successfully submitted.',
    There_something_wrong_when_submitting_comment__please_try_again_later:
      'There something wrong when submitting comment, please try again later',
    The_meeting_has_ended: 'The meeting has ended',
    There_was_a_problem_when_enter_meeting__Please_try_again_:
      'There was a problem when enter meeting. Please try again.',
    There_was_a_problem_when_get_meeting_details__Please_try_again_:
      'There was a problem when get meeting details. Please try again.',
    There_was_a_problem_retrieving_patients__Please_try_again_:
      'There was a problem retrieving patients. Please try again.',
    No_patients_found_: 'No patients found.',
    No_matching_patient_records_found_: 'No matching patient records found.',
    There_was_a_problem_retrieving_patient_details__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving patient details. Please refresh the page to try again.',
    Successfully_updated_your_details_: 'Successfully updated your details.',
    Successfully_updated_patient_details_: 'Successfully updated patient details.',
    There_is_a_problem_when_updating_patient_details__Please_try_again_later_:
      'There is a problem when updating patient details. Please try again later.',
    There_was_a_problem_when_retrieving_patient_s_exams__Please_refresh_the_page_to_try_again_:
      "There was a problem when retrieving patient's exams. Please refresh the page to try again.",
    There_was_a_problem_getting_your_details__Please_try_again_later_:
      'There was a problem getting your details. Please try again later.',
    There_was_a_problem_creating_a_new_patient__Please_try_again_later_:
      'There was a problem creating a new patient. Please try again later.',
    There_was_a_problem_retrieving_price_groups__Please_try_again_later_:
      'There was a problem retrieving price groups. Please try again later.',
    There_was_a_problem_retrieving_your_credits_data__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving your credits data. Please refresh the page to try again.',
    There_was_a_problem_retrieving_the_service_prices__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving the service prices. Please refresh the page to try again.',
    There_was_a_problem_retrieving_your_purchase_history__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving your purchase history. Please refresh the page to try again.',
    There_was_a_problem_retrieving_your_payment_history__Please_refresh_the_page_to_try_again_:
      'There was a problem retrieving your payment history. Please refresh the page to try again.',
    Successfully_added_STDR_Risk_Prediction: 'Successfully added STDR Risk Prediction',
    Successfully_added_DR_Risk_Prediction: 'Successfully added DR Risk Prediction',
    DR_Risk_Prediction_report_ready: 'DR Risk Prediction report ready',
    Successfully_cancelled_subscription__Please_wait_for_a_few_seconds_and_page_will_reload_automatically:
      'Successfully cancelled subscription. Please wait for a few seconds and page will reload automatically',
    Successfully_resumed_subscription__Please_wait_for_a_few_seconds_and_page_will_reload_automatically:
      'Successfully resumed subscription. Please wait for a few seconds and page will reload automatically',
    Confirm: 'Confirm',
    We_ve_Updated_Our_Terms: "We've Updated Our Terms",
    In_order_to_continue_using_TeleEye_MD__you_will_need_to_agree_with_our_latest_Terms_of_Use_:
      'In order to continue using TeleEye MD, you will need to agree with our latest',
    Terms_of_Use: 'Terms of Use',
    progressbar: 'progressbar',
    Login: 'Login',
    There_was_a_problem_connecting_to_the_server__Please_check_your_internet_connection_and_try_again_:
      'There was a problem connecting to the server. Please check your internet connection and try again.',
    _scheduling_simply_book_auth_get_tokens: '/scheduling/simply_book/auth/get_tokens',
    Edit_Comment: 'Edit Comment',
    Submit: 'Submit',
    script: 'script',
    Patient_s_eyes_bleeding__Image_blurry__Let_the_eye_reviewer_know_by_adding_a_comment_:
      "Patient's eyes bleeding? Image blurry? Let the eye reviewer know by adding a comment!",
    No_comment_left: 'No comment left',
    Operator_s_Comments: "Operator's Comments",
    Download_Report: 'Download Report',
    QA: 'QA',
    No_Result: 'No Result',
    Which_Eye_: 'Which Eye?',
    Loading___: 'Loading...',
    Left: 'Left',
    Right: 'Right',
    Upgrade_for: 'Upgrade for',
    for: 'for',
    Go_to_Subscriptions: 'Go to Subscriptions',
    subtitle: 'subtitle',
    Prediction_Occurrence_: 'Prediction Occurrence:',
    View_Report: 'View Report',
    Submit_for_Grading: 'Submit for Grading',
    Order_Services: 'Order Services',
    Submission_not_allowed_: 'Submission not allowed.',
    Please_subscribe_to_a_plan_to_start_screening: 'Please subscribe to a plan to start screening',
    Images_uploaded_but_not_selected: 'Images uploaded but not selected',
    Please_select_images_to_submit_for_grading: 'Please select images to submit for grading',
    Free_Trial: 'Free Trial',
    Free_Plan: 'Free Plan',
    OPEN_MOBILE_APP: 'OPEN MOBILE APP',
    Log_Out: 'Log Out',
    Patient_LookUp: 'Patient LookUp',
    Available_Exams_: 'Available Exams:',
    Account_No_: 'Account No.',
    Terms: 'T&C',
    Below_is_your: 'Below is your',
    Treat_it_as_your_identification_number_when_going_for_any_eye_screening__You_don_t_need_to_remember_it__as_it_is_also_sent_to_your_email_:
      "Treat it as your identification number when going for any eye screening! You don't need to remember it, as it is also sent to your email.",
    This_is_the_patient_s: "This is the patient's",
    Sorry__This_page_does_not_exist_: 'Sorry! This page does not exist.',
    Back_to_Home: 'Back to Home',
    Your_password_needs_to_contain_at_least_8_characters_: 'Your password needs to contain at least 8 characters.',
    Your_password_can_t_be_too_similar_to_your_other_personal_information_:
      "Your password can't be too similar to your other personal information.",
    Your_password_can_t_be_a_commonly_used_password_: "Your password can't be a commonly used password.",
    Your_password_can_t_be_entirely_numeric_: "Your password can't be entirely numeric.",
    Coming_soon: 'Coming soon',
    This_feature_is_currently_still_under_production_: 'This feature is currently still under production.',
    Please_look_forward_to_our_future_releases_: 'Please look forward to our future releases!',
    Cancel: 'Cancel',
    Go_Back: 'Go Back',
    Unknown: 'Unknown',
    Visual_Acuity__Uncorrected_: 'Visual Acuity (Uncorrected)',
    Visual_Acuity__Best_Corrected_: 'Visual Acuity (Best Corrected)',
    IOP__mmHg_: 'IOP (mmHg)',
    HbA1c____: 'HbA1c (%)',
    Blood_Pressure__mmHg_: 'Blood Pressure (mmHg)',
    Albuminuria__mg_mmol_: 'Albuminuria (mg/mmol)',
    Diabetes_Therapy: 'Diabetes Therapy',
    Postprandial_Blood_Glucose__mg_dL_: 'Postprandial Blood Glucose (mg/dL)',
    Triglyceride__mg_dL_: 'Triglyceride (mg/dL)',
    Low_Density_Lipoprotein__mg_dL_: 'Low Density Lipoprotein (mg/dL)',
    No_light_perception__NLP_: 'No light perception (NLP)',
    Light_perception__LP_: 'Light perception (LP)',
    Hand_movements__HM_: 'Hand movements (HM)',
    Counting_fingers_at_1_meters__CF_: 'Counting fingers at 1 meters (CF)',
    Overview: 'Overview',
    All_Patients: 'All Patients',
    Register_Patient: 'Register Patient',
    Subscription: 'Subscription',
    Settings: 'Settings',
    Pending_Reviews: 'Pending Reviews',
    Completed_Reviews: 'Completed Reviews',
    My_TeleConsults: 'My TeleConsults',
    My_Exams: 'My Exams',
    My_Profile: 'My Profile',
    Register_User: 'Register User',
    Invite_User: 'Invite User',
    All_Exams: 'All Exams',
    //newly added for Delete Exam (07-01-2025)
    // -------------------------------------------------------------------------------------------------
    All_Exams_Delete: 'All Exams - Delete',
    Delete_Exam: 'Delete this eye exam',
    LB_delete_exam_desc: 'This eye exam is about to be permanently deleted.',
    LB_yes_permently_delete: 'Yes, permanently delete the eye exam',
    LB_delete_exam: 'Delete Exam',
    //--------------------------------------------------------------------------------------------------
    Approve_Users: 'Approve Users',
    Organisations: 'Organisations',
    According_to_Medicare: 'According to Medicare',
    Country_of_Service: 'Country of Service',
    Medicare_No_: 'Medicare No.',
    Post_Code: 'Post Code',
    Ethnicity: 'Ethnicity',
    NRIC__Last_4_Characters_: 'NRIC (Last 4 Characters)',
    According_to_ID_Passport: 'According to ID/Passport',
    ID_CARD__Personalausweis_: 'ID CARD (Personalausweis)',
    Individual_Health_Identifier__IHI__number: 'Individual Health Identifier (IHI) number',
    NHS_Number: 'NHS Number',
    Postcode: 'Postcode',
    ID_Card_Number: 'ID Card Number',
    REFER_TO_REPORT: 'REFER TO REPORT',
    AI_Grading___: 'AI Grading...',
    Not_Applicable: 'Not Applicable',
    Unable_to_Grade: 'Unable to Grade',
    Non_Referable: 'Non-Referable',
    Referable: 'Referable',
    Not_Urgent: 'Not Urgent',
    Urgent: 'Urgent',
    Resolved: 'Resolved',
    Normal: 'Normal',
    Suspected: 'Suspected',
    Mild: 'Mild',
    Moderate: 'Moderate',
    Severe: 'Severe',
    Non_Significant: 'Non-Significant',
    Significant: 'Significant',
    Mild_NPDR: 'Mild NPDR',
    Moderate_NPDR: 'Moderate NPDR',
    Severe_NPDR: 'Severe NPDR',
    PDR: 'PDR',
    Suspect_DR_Disease: 'Suspect DR Disease',
    Suspect_AMD_Disease: 'Suspect AMD Disease',
    Suspect_AMD_disease__High_probability_AMD__some_AMD_signs_:
      'Suspect AMD disease (High probability AMD, some AMD signs)',
    Suspect_DR_disease__High_probability__some_DR_Signs_: 'Suspect DR disease (High probability, some DR Signs)',
    Click_to_update: 'Click to update',
    RetinoScan: 'RetinoScan',
    RetinoScan_analyses_retinal_images_to_accurately_identify_patients__with_Diabetic_Retinopathy__Glaucoma_and_or_Age_Related_Macular_Degeneration_:
      "RetinoScan analyses retinal images to accurately identify patients' with Diabetic Retinopathy, Glaucoma and/or Age Related Macular Degeneration.",
    RetinoScan_Plus: 'RetinoScan+',
    For_patients_with_a_disease_diagnosis__RetinoScan_Advanced_determines_the_disease_severity_and_provide_lesion_monitoring_services_:
      'For patients with a disease diagnosis, RetinoScan Advanced determines the disease severity and provide lesion monitoring services.',
    Suspected_Cases: 'Suspected Cases',
    Review_by_Eye_Professional: 'Review by Eye Professional',
    Comprehensive_Patient_Report: 'Comprehensive Patient Report',
    Class_II_Medical_Device: 'Class II Medical Device',
    Clinically_Validated: 'Clinically Validated',
    Cloud_Storage: 'Cloud Storage',
    Patient_Data_Retention: 'Patient Data Retention',
    In_accordance_to_HIPPA_compliances__all_patients__data_will_be_stored_and_backed_up_for_15_years:
      "In accordance to HIPPA compliances, all patients' data will be stored and backed up for 15 years",
    '15_years': '15 years',
    Referral_Pathway: 'Referral Pathway',
    Coming_Soon: 'Coming Soon',
    Forgot_your_password_: 'Forgot your password?',
    We_can_send_you_an_email_with_the_link_to_reset_it_: 'We can send you an email with the link to reset it.',
    E_mail: 'E-mail',
    SEND_EMAIL: 'SEND EMAIL',
    Check_your_email: 'Check your email',
    If_your_email_exist_in_our_system_the_reset_link_will_be_sent_to_your_inbox_Please_do_also_check_if_it_s_in_your_spam_folder_:
      "If your email exist in our system,the reset link will be sent to your inbox.Please do also check if it's in your spam folder.",
    Invalid_password: 'Invalid password',
    Create_new_password: 'Create new password',
    Please_enter_a_new_password_to_reset_your_password_: 'Please enter a new password to reset your password.',
    New_Password: 'New Password',
    toggle_password_visibility: 'toggle password visibility',
    Confirm_Password: 'Confirm Password',
    Passwords_must_be_equal: 'Passwords must be equal',
    RESET_PASSWORD: 'RESET PASSWORD',
    Password_Changed: 'Password Changed',
    You_have_successfully_changed_your_password__Try_logging_in_now_:
      'You have successfully changed your password! Try logging in now!',
    Password_Change_Unsuccessful: 'Password Change Unsuccessful',
    Sorry__There_must_be_a_problem_on_our_side__Please_contact_customer_support_to_report_this_problem_:
      'Sorry! There must be a problem on our side. Please contact customer support to report this problem.',
    Wrong_digit_code__Try_Again: 'Wrong digit code. Try Again',
    Enter_your_Email_or_Mobile_number: 'Enter your Email or Mobile number',
    NEXT: 'NEXT',
    next: 'next',
    Log_in: 'Log in',
    Login_with_your_Email_or_Mobile_No_: 'Login with your Email or Mobile No.',
    Forgot_Password: 'Forgot Password',
    LOGIN: 'LOGIN',
    Don_t_have_an_account_: "Don't have an account?",
    Sign_Up: 'Sign Up',
    You_may_now_log_into_your_account_with_your_email_: 'You may now log into your account with your email.',
    You_are_already_logged_in_: 'You are already logged in.',
    If_you_have_already_logged_in__please_log_out_first_and_refresh_this_page_If_not__please_contact_our_customer_support_to_report_this_problem_:
      'If you have already logged in, please log out first and refresh this page.If not, please contact our customer support to report this problem.',
    LOG_OUT: 'LOG OUT',
    success: 'success',
    Activation_Success_: 'Activation Success!',
    Activation_Fail___: 'Activation Fail...',
    Sorry__You_might_have_already_been_activated__so_try_to_log_in__If_not_please_contact_our_customer_service_to_report_this_problem_:
      'Sorry! You might have already been activated, so try to log in. If not,please contact our customer service to report this problem.',
    Please_set_your_password_so_that_you_can_use_it_to_log_into_this_platform_:
      'Please set your password so that you can use it to log into this platform.',
    SET_PASSWORD: 'SET PASSWORD',
    Password_Successfully_Set_: 'Password Successfully Set!',
    You_may_now_log_into_your_account_with_your_credentials_:
      'You may now log into your account with your credentials.',
    Password_Set_Unsuccessful___: 'Password Set Unsuccessful...',
    OTP_Verification: 'OTP Verification',
    Enter_the_6_digit_code_sent_to_: 'Enter the 6 digit code sent to:',
    Didn_t_receive_the_code_: "Didn't receive the code?",
    Resend: 'Resend',
    I_am_a____: 'I am a ...',
    Already_have_an_account_: 'Already have an account?',
    Log_In: 'Log In',
    SIGN_UP: 'SIGN UP',
    EMAIL: 'EMAIL',
    MOBILE_NUMBER: 'MOBILE NUMBER',
    VERIFIED: 'VERIFIED',
    email_address: 'email address',
    mobile_number: 'mobile number',
    Your_TeleEye_MD_account_is_now_activated_: 'Your TeleEye MD account is now activated.',
    It_will_take_up_to_1_business_day_for_TeleMedC_admins_to_verify_your_details_and_activate_your_account_:
      'It will take up to 1 business day for TeleMedC admins to verify your details and activate your account.',
    Save: 'Save',
    Edit_Data: 'Edit Data',
    Account_Number: 'Account Number',
    Not_available: 'Not available',
    Patient_s_account_number_is_unique_and_not_available_for_modification_:
      "Patient's account number is unique and not available for modification.",
    First_Name: 'First Name',
    Last_Name: 'Last Name',
    Date_of_Birth: 'Date of Birth',
    Gender: 'Gender',
    select_gender_label: 'select-gender-label',
    select_ethnicity_label: 'select-ethnicity-label',
    These_reviews_have_been_assigned_to_you__please_complete_them_as_soon_as_possible_:
      'These reviews have been assigned to you, please complete them as soon as possible.',
    There_are_currently_no_exams_available_for_review_right_now_:
      'There are currently no exams available for review right now.',
    Leave_this_Page_: 'Leave this Page?',
    The_changes_you_made_will_not_be_saved_and_the_review_will_be_returned_to_the_list_of_pending_reviews_:
      'The changes you made will not be saved and the review will be returned to the list of pending reviews.',
    Reviewed_Exam: 'Reviewed Exam',
    Review_Exam: 'Review Exam',
    This_Exam_Review_is_from_the_Pool__it_will_be_reassigned_if_this_page_is_closed_changed_:
      'This Exam Review is from the Pool, it will be reassigned if this page is closed/changed.',
    Please_fill_out_all_required_fields: 'Please fill out all required fields',
    View_completed_reviews_: 'View your completed reviews',
    View: 'View',
    There_are_currently_no_completed_reviews_: 'There are currently no completed reviews.',
    There_are_currently_no_saved_reviews_: 'There are currently no saved reviews',
    NON_URGENT: 'NON URGENT',
    URGENT: 'URGENT',
    Eye_exam_determined_as______: 'Eye exam determined as . . .',
    If_this_case_is_URGENT__we_recommend_you_to_contact_the_patient_immediately_:
      'If this case is URGENT, we recommend you to contact the patient immediately.',
    Requires_referral_to_ophthalmologist_additional_actions_:
      'Requires referral to ophthalmologist/additional actions:',
    follow_up_screening_label: 'follow-up-screening-label',
    Select: 'Select',
    Can_be_viewed_only_by_the_patient_s: 'Can be viewed only by the patient’s',
    Write_comment_here___: 'Write comment here...',
    Can_be_viewed_by_both_the_patient_and_practitioners_within_TeleEye_MD_and_the_report:
      'Can be viewed by both the patient and practitioners within TeleEye MD and the report',
    Can_be_viewed_within_TeleEye_MD_and_the_exam_report: 'Can be viewed within TeleEye MD and the exam report',
    Patient_s_Profile: "Patient's Profile",
    All_exam_reviews_are_taken__please_refresh_to_see_more_reviews_:
      'All exam reviews are taken, please refresh to see more reviews.',
    Filter_By_Group: 'Filter By Group',
    Total_reviews_pending: 'Total reviews pending',
    Review__for_suspected_cases_only_: 'Review (for suspected cases only)',
    Upload_Images: 'Upload Images',
    Order_Summary: 'Order Summary',
    Order_Eye_Exam: 'Order Eye Exam',
    Order_exam_without_eye_images: 'Order exam without eye images',
    Eye_Exam: 'Eye Exam',
    Reviewed_by: 'Reviewed by',
    The_next_available_eye_reviewer: 'The next available eye reviewer',
    Current_Balance: 'Current Balance',
    Balance_after_Order: 'Balance after Order',
    Eye_reviewer_s_Name: "Eye reviewer's Name",
    Eye_Reviewer_s_Name: "Eye Reviewer's Name",
    Only_suspected_cases_are_reviewed_: 'Only suspected cases are reviewed.',
    Any_Eye_Reviewer: 'Any Eye Reviewer',
    This_exam_will_be_submitted_to_the_pool_for_eye_professionals_review_as_soon_as_possible_:
      'This exam will be submitted to the pool for eye professionals review as soon as possible.',
    Bad_Quality_Images_may_not_be_properly_graded_by_DR_Lite__Please_select_DR_Grader_if_you_wish_to_continue_:
      'Bad Quality Images may not be properly graded by DR Lite. Please select DR Grader if you wish to continue.',
    Left_Right_not_assigned_to_image_: 'Left/Right not assigned to image.',
    Only_a_maximum_of_2_images_of_left_eye_is_allowed_for_submission_:
      'Only a maximum of 2 images of left eye is allowed for submission.',
    Only_a_maximum_of_2_images_of_right_eye_is_allowed_for_submission_:
      'Only a maximum of 2 images of right eye is allowed for submission.',
    Please_select_your_desired_services_for_the_eye_images_: 'Please select your desired services for the eye images.',
    Possible_reasons_for_bad_quality_image_: 'Possible reasons for bad quality image:',
    '1__Blurred_image': '1. Blurred image',
    '2__Low_illumination': '2. Low illumination',
    '3__Optic_lens_reflection': '3. Optic lens reflection',
    Could_be_affected_by_: 'Could be affected by:',
    '1__Optic_lens_opacity_issue': '1. Optic lens opacity issue',
    '2__Improper_operation': '2. Improper operation',
    '3__Dirty_camera_lens': '3. Dirty camera lens',
    '3__Small_pupil': '3. Small pupil',
    Create_Exam: 'Create Exam',
    ADDED_SERVICES: 'ADDED SERVICES',
    ADDED_PLAN: 'ADDED PLAN',
    SELECT_REVIEWER: 'SELECT REVIEWER',
    requirements: 'requirements',
    ADD_A_COMMENT__OPTIONAL_: 'ADD A COMMENT (OPTIONAL)',
    Color_fundus_images: 'Color fundus images',
    The_angle_of_retina_field_of_view__FOV__from_the_image_is_between_35_and_50_degrees:
      'The angle of retina field-of-view (FOV) from the image is between 35 and 50 degrees',
    Minimum_size_of_the_retina_FOV_to_be_1000_x_1000_pixels: 'Minimum size of the retina FOV to be 1000 x 1000 pixels',
    Macula_centred_images: 'Macula-centred images',
    PNG_or_JPG_format: 'PNG or JPG format',
    Auto_Select: 'Auto-Select',
    Deselect_All: 'Deselect All',
    You_can_only_upload_a_maximum_of_4_images_: 'You can only upload a maximum of 4 images.',
    Click_or_drop_an_image_here_to_upload: 'Click or drop an image here to upload',
    Failed_to_add_services_or_show_exam_details__Refresh_or_try_again_:
      'Failed to add services or show exam details. Refresh or try again.',
    Add_More_Services: 'Add More Services',
    You_may_choose_to_select_more_services_to_add_to_your_exam_:
      'You may choose to select more services to add to your exam.',
    AI_grading_is_taking_longer_than_expected______: 'AI grading is taking longer than expected . . .',
    You_might_want_to_check_your_network_and_resubmit_it_again_:
      'You might want to check your network and resubmit it again.',
    Please_contact_our_support_for_help: 'Please contact our support for help',
    PENDING_REVIEW: 'PENDING REVIEW',
    The_eye_exam_results_has_been_sent_to_an_eye_health_professional_to_review_:
      'The eye exam results has been sent to an eye health professional to review.',
    Please_check_back_after_a_while_: 'Please check back after a while.',
    Email_Sent: 'Email Sent',
    SMS_Sent: 'SMS Sent',
    Send_a_report_link_to_the_patient_through_the_chosen_method_:
      'Send a report link to the patient through the chosen method.',
    Email: 'Email',
    Invalid_email: 'Invalid email',
    Send_Email: 'Send Email',
    SMS: 'SMS',
    _Coming_Soon_: '(Coming Soon)',
    Send_SMS: 'Send SMS',
    Reviewed_on: 'Reviewed on',
    yyyy_MM_dd_hh_mmaaa: 'yyyy-MM-dd hh:mmaaa',
    Recommended_next_eye_exam_in: 'Recommended next eye exam in',
    Upgrade_to_proactively_participate_in_your_own_well_being_and_care_:
      'Upgrade to proactively participate in your own well-being and care.',
    Detect_the_severity_of_Diabetic_Retinopathy___Glaucoma__with_Cup_to_disc_Ratio_:
      'Detect the severity of Diabetic Retinopathy & Glaucoma (with Cup-to-disc Ratio)',
    Download_Mobile_App_to_Purchase: 'Download Mobile App to Purchase',
    Participate_in_telehealth_consultations_with_healthcare_providers_for_guidance_and_feedback_on_your_most_recent_eye_exam:
      'Participate in telehealth consultations with healthcare providers for guidance and feedback on your most recent eye exam',
    Go_to_My_TeleConsult: 'Go to My TeleConsult',
    Eye_Exam_Results: 'Eye Exam Results',
    Report_is_not_ready: 'Report is not ready',
    The_report_s__will_be_ready_for_download_after_all_exam_are_graded_and_reviewed__suspected_cases_only_:
      'The report(s) will be ready for download after all exam are graded and reviewed (suspected cases only)',
    Download_Final_Reports: 'Download Final Reports',
    Right_Eye: 'Right Eye',
    Left_Eye: 'Left Eye',
    No_Eyes_Captured: 'No Eyes Captured',
    No_image_to_show_: 'No image to show',
    right_eye: 'right-eye',
    left_eye: 'left-eye',
    Enter_Cup_to_Disc_Ratio: 'Enter Cup to Disc Ratio',
    Leave_field_blank_if_it_s_unable_to_grade: "Leave field blank if it's unable to grade",
    Status: 'Status',
    DR_Lite: 'DR Lite',
    DR_Grader: 'DR Grader',
    C_D_Ratio: 'C/D Ratio',
    Your_organisation_has_yet_to_create_any_exams_: 'Your organisation has yet to create any exams.',
    You_may_select_to_view_any_of_your_past_exams_in_detail_:
      'You may select to view any of your past exams in detail.',
    You_have_not_completed_any_exams_: 'You have not completed any exams.',
    Confirm_Submission: 'Confirm Submission',
    Submitting_images_for_grading_: 'Submitting images for grading.',
    Confirm_and_Grade: 'Confirm and Grade',
    Confirm_and_Order: 'Confirm and Order',
    Total_Image_s_: 'Total Image(s)',
    Ordered_Services: 'Ordered Services',
    Exam_will_be_audited_by: 'Exam will be audited by',
    The_next_available_auditor: 'The next available auditor',
    Please_select_images_to_continue: 'Please select images to continue',
    Ordered_Exam: 'Ordered Exam',
    EYE_REVIEWER: 'EYE REVIEWER',
    Any_eye_reviewer: 'Any eye reviewer',
    Next_Steps: 'Next Steps',
    UPLOAD_IMAGES_OR_WAIT_FOR_PATIENT_TO_VISIT_OPTICAL_STORE:
      'UPLOAD IMAGES OR WAIT FOR PATIENT TO VISIT OPTICAL STORE',
    Upload_and_select_images_to_be_sent_for_grading__with_several_:
      'Upload and select images to be sent for grading, with several',
    This_Report_is_Protected: 'This Report is Protected',
    To_unlock_and_view_report__enter_your_last_name_and_DOB: 'To unlock and view report, enter your last name and DOB',
    Unlock_Report: 'Unlock Report',
    Having_issues_unlocking_report__Contact_your_eye_screening_location_:
      'Having issues unlocking report? Contact your eye screening location.',
    There_are_no_urgent_cases_to_show_: 'There are no urgent cases to show.',
    Please_enter_a_number_between_0_to_300_inclusive: 'Please enter a number between 0 to 300 inclusive',
    Please_enter_a_number_between_0_to_700_inclusive: 'Please enter a number between 0 to 700 inclusive',
    Please_enter_a_number_between_0_to_400_inclusive: 'Please enter a number between 0 to 400 inclusive',
    Please_enter_a_number_between_0_to_50_inclusive: 'Please enter a number between 0 to 50 inclusive',
    Please_enter_a_number_between_0_to_250_inclusive: 'Please enter a number between 0 to 250 inclusive',
    Please_enter_a_number_between_0_to_20_inclusive: 'Please enter a number between 0 to 20 inclusive',
    Systolic_Blood_Pressure: 'Systolic Blood Pressure',
    Diastolic_Blood_Pressure: 'Diastolic Blood Pressure',
    Health_Data_Type: 'Health Data Type',
    select_diabetes_therapy_label: 'select-diabetes-therapy-label',
    Please_select_any_health_data_to_add_: 'Please select any health data to add.',
    Recorded_Date_and_Time: 'Recorded Date and Time',
    Now: 'Now',
    New_Data: 'New Data',
    Recorded_Date: 'Recorded Date',
    Data: 'Data',
    Medical_History: 'Medical History',
    Diabetes_Type: 'Diabetes Type',
    select___: 'select...',
    No_diabetes: 'No diabetes',
    Type_1_Diabetes: 'Type 1 Diabetes',
    Type_2_Diabetes: 'Type 2 Diabetes',
    Gestational_Diabetes: 'Gestational Diabetes',
    Diabetes_Onset: 'Diabetes Onset',
    When_were_you_first_diagnosed_with_diabetes_: 'When were you first diagnosed with diabetes?',
    Eye_Surgery_History: 'Eye Surgery History',
    Had_eye_surgery: 'Had eye surgery',
    Never_had_eye_surgery: 'Never had eye surgery',
    Heart_Disease: 'Heart Disease',
    Has_heart_disease: 'Has heart disease',
    No_heart_disease: 'No heart disease',
    High_Blood_Pressure: 'High Blood Pressure',
    Yes: 'Yes',
    No: 'No',
    High_Cholesterol: 'High Cholesterol',
    No_Disease: 'No Disease',
    Mild_Retinopathy: 'Mild Retinopathy',
    Moderate_Retinopathy: 'Moderate Retinopathy',
    Severe_Retinopathy: 'Severe Retinopathy',
    Proliferative_Retinopathy: 'Proliferative Retinopathy',
    Macular_Edema_Presence: 'Macular Edema Presence',
    select_macular_edema_presence_label: 'select-macular-edema-presence-label',
    Absent: 'Absent',
    Present: 'Present',
    Enter_account_number_to_retrieve_patient_: 'Enter account number to retrieve patient.',
    Dashboard: 'Dashboard',
    Disclaimer__statistics_are_non_realtime_and_are_updated_midnightly_:
      'Disclaimer: statistics are non-realtime and are updated midnightly.',
    Welcome_back_: 'Welcome back',
    Have_a_nice_day_at_work: 'Have a nice day at work',
    Total_Patients: 'Total Patients',
    Urgent_Cases: 'Urgent Cases',
    Total_Eye_Exams: 'Total Eye Exams',
    Completed: 'Completed',
    DISEASE_DETECTION_SUMMARY: 'DISEASE DETECTION SUMMARY',
    __Diseases_are_those_marked_as_moderate_or_greater__AMD_and_DR___or_suspect__DME__Glaucoma_and_Media_Opacity_:
      '* Diseases are those marked as moderate or greater (AMD and DR), or suspect (DME, Glaucoma and Media Opacity)',
    Total_number_of_images_graded_by_TeleEye_MD: 'Total number of images graded by TeleEye MD',
    URGENT_CASES: 'URGENT CASES',
    Select_Organisation: 'Select Organisation',
    Approve_User: 'Approve User',
    Create_User: 'Create User',
    Registration_Success_: 'Registration Success!',
    Create_another_user: 'Create another user',
    Male: 'Male',
    Female: 'Female',
    Other: 'Other',
    Start_typing_for_suggestions: 'Start typing for suggestions',
    Type_of_Camera_used_: 'Type of Camera used:',
    Organisation_Name: 'Organisation Name',
    Which_hospital__clinic_or_establishment_do_you_belong_to_:
      'Which hospital, clinic or establishment do you belong to?',
    Role: 'Role',
    Where_do_you_intend_to_practice_: 'Where do you intend to practice?',
    Where_do_you_intend_to_get_screened_: 'Where do you intend to get screened?',
    Password: 'Password',
    I_agree_to_the: 'I agree to the',
    Via_Invite_Link: 'Via Invite Link',
    Copy_Link: 'Copy Link',
    OR: 'OR',
    Email_Address: 'Email Address',
    Type_a_valid_email_and_hit_enter_or_copy_and_paste_multiple_emails:
      'Type a valid email and hit enter or copy and paste multiple emails',
    Invite_Users: 'Invite Users',
    Invitation_Success_: 'Invitation Success!',
    Almost_there__Please_ask_the_user_to_check_their_email_in_their_inbox_or_spam_folder_and_click_on_the_link_to_complete_their_registration_process_:
      'Almost there! Please ask the user to check their email in their inbox or spam folder and click on the link to complete their registration process.',
    Add_New_Organisation: 'Add New Organisation',
    Email_or_Mobile_No_: 'Email or Mobile No.',
    Mobile_Number: 'Mobile Number',
    Invalid_Input: 'Invalid Input',
    Invalid_medicare_number: 'Invalid medicare number',
    Invalid_reference_number: 'Invalid reference number',
    Select_Country: 'Select Country',
    Country: 'Country',
    Passwords_do_not_match: 'Passwords do not match',
    Password_must_be_at_least_8_characters: 'Password must be at least 8 characters',
    Password_must_contain_at_least_one_letter: 'Password must contain at least one letter',
    Password_must_contain_at_least_one_special_character: 'Password must contain at least one special character',
    password_confirm: 'password_confirm',
    Please_fill_up_all_required_fields_and_check_for_any_errors:
      'Please fill up all required fields and check for any errors',
    Please_agree_to_all_terms_and_conditions: 'Please agree to all terms and conditions',
    edit: 'edit',
    I_declare_that_the_above_information_is_true_and_correct_:
      'I declare that the above information is true and correct.',
    Enter_your_details: 'Enter your details',
    Booking_Successful: 'Booking Successful',
    BACK_TO_MEETINGS: 'BACK TO MEETINGS',
    There_is_no_meeting_available_today__please_try_another_day_:
      'There is no meeting available today, please try another day.',
    First_name: 'First name',
    Last_name: 'Last name',
    invalid_email_address: 'invalid email address',
    Phone: 'Phone',
    Teleconsultation: 'Teleconsultation',
    Contact_Details: 'Contact Details',
    Teleconsultation_Details: 'Teleconsultation Details',
    Today__HH_mm: "Today' HH:mm",
    Tomorrow__HH_mm: "Tomorrow' HH:mm",
    no_available_time: 'no available time',
    loading___: 'loading...',
    Details: 'Details',
    SimplyBook_me: 'SimplyBook.me',
    Add_a_Note__optional_: 'Add a Note (optional)',
    Please_describe_your_symptoms_or_leave_your_questions: 'Please describe your symptoms or leave your questions',
    Confirm_Booking: 'Confirm Booking',
    Next: 'Next',
    Available_Meeting_Start_Times: 'Available Meeting Start Times',
    JOIN: 'JOIN',
    VIEW_REPORT: 'VIEW REPORT',
    VIEW_NOTE: 'VIEW NOTE',
    Select_a_status_to_display_: 'Select a status to display.',
    The_patient_did_not_leave_a_note: 'The patient did not leave a note',
    The_eye_specialist_has_not_left_a_note_yet: 'The eye specialist has not left a note yet',
    Enter_any_notes_from_the_meeting_here______: 'Enter any notes from the meeting here . . .',
    Eye_Specialist: 'Eye Specialist',
    Patient_Name: 'Patient Name',
    Notes: 'Notes',
    Eye_Exam_Reports: 'Eye Exam Reports',
    View_Report_s_: 'View Report(s)',
    The_patient_doesn_t_have_any_history_meetings: "The patient doesn't have any history meetings",
    You_don_t_have_any_history_meetings_: "You don't have any history meetings.",
    Enable_TeleConsult: 'Enable TeleConsult',
    TeleConsults_History: 'TeleConsults History',
    TeleConsults: 'TeleConsults',
    Feature_unavailable: 'Feature unavailable',
    The_feature_is_currently_unavailable: 'The feature is currently unavailable',
    You_need_to_finish_an_eye_exam_to_book_a_TeleConsult: 'You need to finish an eye exam to book a TeleConsult',
    BOOK: 'BOOK',
    Meeting_room_will_open_5_min_before_the_meeting_begins: 'Meeting room will open 5 min before the meeting begins',
    You_don_t_have_any_upcoming_meetings_: "You don't have any upcoming meetings.",
    contact_us_: 'contact us.',
    New_Patient_Creation: 'New Patient Creation',
    This_patient_does_not_have_any_existing_exams_: 'This patient does not have any existing exams.',
    Add_New_Exam: 'Add New Exam',
    Unable_to_add_new_exam: 'Unable to add new exam',
    Each_patient_can_only_have_a_maximum_of_50_exams_: 'Each patient can only have a maximum of 50 exams.',
    Please_complete_and_submit_the_most_recent_exam_to_add_new_exam_:
      'Please complete and submit the most recent exam to add new exam.',
    Risk_Analysis: 'Risk Analysis',
    Risk_Analysis_Unavailable: 'Risk Analysis Unavailable',
    Please_add_at_least_one_Risk_Prediction_to_view_Risk_Analaysis:
      'Please add at least one Risk Prediction to view Risk Analaysis',
    This_feature_will_be_coming_soon__stay_tuned_: 'This feature will be coming soon, stay tuned!',
    Add_Risk_Prediction: 'Add Risk Prediction',
    Risk_Prediction_Services_Coming_Soon___: 'Risk Prediction Services Coming Soon...',
    Enter_name_s__and_or_date_of_birth_to_retrieve_patient_: 'Enter name(s) and/or date of birth to retrieve patient.',
    Please_enter_some_patient_information_to_search: 'Please enter some patient information to search',
    Use_Account_Number: 'Use Account Number',
    No_Account_Number: 'No Account Number',
    Your_organisation_has_yet_to_register_any_patients_: 'Your organisation has yet to register any patients.',
    UPCOMING_APPOINTMENTS: 'UPCOMING APPOINTMENTS',
    Credits: 'Credits',
    Add_Credits: 'Add Credits',
    Add_free_credits_to_specific_organisation_below_: 'Add free credits to specific organisation below.',
    Completed_By: 'Completed By',
    You_have_yet_to_make_any_purchases_: 'You have yet to make any purchases.',
    Payment_Transactions: 'Payment Transactions',
    First_Time_offer_: 'First Time offer!',
    You_pay: 'You pay',
    Your_payment_has_been_cancelled__If_there_was_a_problem_with_the_transaction__please_contact_our_customer_support_or_try_again_:
      'Your payment has been cancelled. If there was a problem with the transaction, please contact our customer support or try again.',
    Total_Credits: 'Total Credits',
    CREDITS: 'CREDITS',
    Credit_Transactions: 'Credit Transactions',
    Subscription_success_: 'Subscription success!',
    Unit_Price: 'Unit Price',
    Max__Exams: 'Max. Exams',
    Paid_By: 'Paid By',
    You_have_yet_to_make_any_payment_: 'You have yet to make any payment.',
    What_will_be_different_: 'What will be different?',
    Join: 'Join',
    Please_select_a_service_to_continue_: 'Please select a service to continue.',
    You_may_select_more_services_to_add_to_calculate_different_risks_:
      'You may select more services to add to calculate different risks.',
    Medical_History_and_Health_Data_required_to_run_each_service_:
      'Medical History and Health Data required to run each service:',
    Risk_Predictions: 'Risk Predictions',
    Resume_Subscription: 'Resume Subscription',
    Cancel_Subscription: 'Cancel Subscription',
    Current_Plan: 'Current Plan',
    Cancelled_subscription_ends_on: 'Cancelled subscription ends on',
    __Trial_ends_on: '- Trial ends on',
    Next_bill_of: 'Next bill of',
    to_be_issued_on: 'to be issued on',
    Plan_: 'Plan:',
    Add_On: 'Add-On',
    Start_your_free_trial_today: 'Start your free trial today',
    By_choosing_a_subscription_package_that_suits_you_: 'By choosing a subscription package that suits you.',
    Unable_to_view_Billing_History: 'Unable to view Billing History',
    Billing_history_is_currently_unavailable_but_will_be_coming_soon_:
      'Billing history is currently unavailable but will be coming soon.',
    Billing_History: 'Billing History',
    We_are_sad_to_see_you_go__If_you_choose_to_cancel_now_: 'We are sad to see you go. If you choose to cancel now,',
    Cancellation_will_be_effective_at_the_end_of_your_current_billing_cycle_on:
      'Cancellation will be effective at the end of your current billing cycle on',
    You_can_still_use_the_service_until_then_: 'You can still use the service until then.',
    We_are_here_to_welcome_you_back_at_anytime__If_you_choose_to_resume_now_:
      'We are here to welcome you back at anytime. If you choose to resume now,',
    Your_next_payment_will_be_processed_on: 'Your next payment will be processed on',
    Your_subscription_will_continue_as_before_: 'Your subscription will continue as before.',
    Select_No__of_Exams_: 'Select No. of Exams:',
    Add_On_Purchase_Not_Available: 'Add-On Purchase Not Available',
    Finish_using_current_available_Add_On_exams_before_purchasing_more:
      'Finish using current available Add-On exams before purchasing more',
    One_time_Purchase: 'One-time Purchase',
    Subscriptions: 'Subscriptions',
    Add_On_Unavailable: 'Add-On Unavailable',
    Add_On_is_only_available_once_you_purchase_a_subscription_plan:
      'Add-On is only available once you purchase a subscription plan',
    Purchase: 'Purchase',
    Switch_Plans_Coming_Soon: 'Switch Plans Coming Soon',
    _For_enterprise_pricings__please: '*For enterprise pricings, please',
    Error_during_service_worker_registration_: 'Error during service worker registration:',
    content_type: 'content-type',
    Upgrade_your_subscription_if_you_wish_to_select_this_service:
      'Upgrade your subscription if you wish to select this service',
    Basic_Plan_required_: 'Basic Plan required!',
    Standard_Plan_required_: 'Standard Plan required!',
    Premium_Plan_required_: 'Premium Plan required!',
    AMD: 'AMD',
    DME: 'DME',
    DR: 'DR',
    GLAUCOMA: 'GLAUCOMA',
    MEDIA_OPACITY: 'MEDIA OPACITY',
    Disclaimer__RetinoScan_Glaucoma_and_AMD_is_for: 'Disclaimer: RetinoScan Glaucoma and AMD is for',
    research_use: 'research use',
    only_and_should_not_be_used_for_clinical_decision_making_:
      'only and should not be used for clinical decision making.',
    Result: 'Result',
    Upload_and_select_up_to_4_images_to_be_sent_for_grading__with_several:
      'Upload and select up to 4 images to be sent for grading, with several',
    Create: 'Create',
    User: 'User',
    Health_Condition____Right_Eye____Left_Eye: "Health Condition', 'Right Eye', 'Left Eye",
    Health_Condition: 'Health Condition',
    health_practitioners: 'health practitioners',
    within_TeleEye_MD: 'within TeleEye MD',
    You_can_navigate_away_from_this_page_while_waiting_for_results__start_another_exam__and_return_at_any_time_:
      'You can navigate away from this page while waiting for results, start another exam, and return at any time.',
    Create_Patient: 'Create Patient',
    Total_Patients_Screened: 'Total Patients Screened',
    Exam_Date: 'Exam Date',
    PENDING_ACTION: 'PENDING ACTION',
    Filter: 'Filter',
    AWAITING_RESULT: 'AWAITING RESULT',
    Chinese: 'Chinese',
    Indian: 'Indian',
    Malay: 'Malay',
    Caucasian: 'Caucasian',
    Patient_Details: 'Patient Details',
    Latest_Health_Data: 'Latest Health Data',
    Severity: 'Severity',
    Add_Comment: 'Add Comment',
    Eye_Images: 'Eye Images',
    DR_Presence: 'DR Presence',
    By_logging_in__you_agree_to_our: 'By logging in, you agree to our',
    Reviewer: 'Reviewer',
    Reviews_within: 'Reviews within',
    Oldest_First: 'Oldest First',
    Newest_First: 'Newest First',
    Default__All_: 'Default (All)',
    Phone_Number: 'Phone Number',
    If_you_have_the_user_s_email_address_that_is_within_your_organisation__you_can_invite_them_by_entering_their_email_addres_below__Instructions_will_be_sent_to_their_email_and_once_completed__they_will_be_added_to_the_organisation_:
      "If you have the user's email address that is within your organisation, you can invite them by entering their email addres below. Instructions will be sent to their email and once completed, they will be added to the organisation.",
    Invite_another_user: 'Invite another user',
    Practitioner: 'Practitioner',
    Patient: 'Patient',
    Optometrist: 'Optometrist',
    Sign_Up_with_your_Email: 'Sign Up with your Email',
    Branch: 'Branch',
    Location: 'Location',
    Branch_Location: 'Branch/Location',
    Your_practice_branch_name_or_location: 'Your practice branch name or location',
    Age_Related_Macular_Degeneration: 'Age-Related Macular Degeneration',
    for_age: 'for age',
    Glaucoma: 'Glaucoma',
    No_Image: 'No Image',
    DR_Severity: 'DR Severity',
    Exam_Details: 'Exam Details',
    Cup_to_Disc_Ratio: 'Cup to Disc Ratio',
    Disc_Asymmetry: 'Disc Asymmetry',
    Risk_of_Glaucoma_if: 'Risk of Glaucoma if',
    Send_Report: 'Send Report',
    REPORT_READY: 'REPORT READY',
    Additional_Information: 'Additional Information',
    Information: 'Information',
    Comment_to_Health_Practitioners_Only: 'Comment to Health Practitioners Only',
    Comment_to_Patient: 'Comment to Patient',
    Accident_and_Emergency: 'Accident and Emergency',
    '2_weeks': '2 weeks',
    '1_month': '1 month',
    '3_months': '3 months',
    '6_months': '6 months',
    Normal_screening_results___continue_annual_screening: 'Normal screening results - continue annual screening',
    No_referable_conditions_in_screening_results___Continue_annual_screening:
      'No referable conditions in screening results - Continue annual screening',
    Extra_Parameters: 'Extra Parameters',
    Parameters: 'Parameters',
    Diabetic_Macular_Edema: 'Diabetic Macular Edema',
    Media_Opacity: 'Media Opacity',
    Review_Summary: 'Review Summary',
    Reviewed_at: 'Reviewed at',
    please_login_instead: 'please login instead',
    or: 'or',
    To_enhance_your_account_s_security_: "To enhance your account's security:",
    Street_Address: 'Street Address',
    Street: 'Street',
    Address_Line_2__Floor_and_or_unit_number: 'Address Line 2: Floor and/or unit number',
    Suburb_City: 'Suburb/City',
    City: 'City',
    Town_City: 'Town/City',
    State: 'State',
    Type: 'Type',
    An_account_already_exists_for_this: 'An account already exists for this',
    Neighbourhood: 'Neighbourhood',
    organisation_admin: 'organisation admin',
    Organisation_Admin: 'Organisation Admin',
    Detected_Diseases: 'Detected Diseases',
    UNLIMITED: 'UNLIMITED',
    unlimited: 'unlimited',
    Image_Requirements: 'Image Requirements',
    Start_New_Exam: 'Start New Exam',
    Review: 'Review',
    Leave: 'Leave',
    Stay: 'Stay',
    Health_Data: 'Health Data',
    Eye_Exams: 'Eye Exams',
    Add_Health_Data: 'Add Health Data',
    Right_Uncorrected: 'Right Uncorrected',
    Left_Uncorrected: 'Left Uncorrected',
    Right_Best_Corrected: 'Right Best Corrected',
    Left_Best_Corrected: 'Left Best Corrected',
    Comments__optional_: 'Comments (optional)',
    Comments: 'Comments',
    e_g__Images_too_blurry__eyes_bleeding___: 'e.g. Images too blurry, eyes bleeding...',
    Submit_Exam_: 'Submit Exam?',
    Submit_Review_: 'Submit Review?',
    Provider_Number: 'Provider Number',
    Aboriginal: 'Aboriginal',
    Torres_Strait_Islander: 'Torres Strait Islander',
    Aboriginal_and_Torres_Strait_Islander: 'Aboriginal and Torres Strait Islander',
  },
};
