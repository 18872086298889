import { trackPromise } from 'react-promise-tracker';
import axiosInstance from '../axios';
import {
  GET_SUBSCRIPTION_PLANS,
  SET_SNACKBAR_ERROR,
  SET_SNACKBAR_SUCCESS,
  SUBSCRIPTION_SUCCESS,
  UPDATE_URL_RETRIEVED,
  ADD_ON_PURCHASED,
  GET_BILLING_DETAILS,
  LOADER,
} from './types';
import { RETINOSCAN_PLANS } from '../constants/subscriptions';
import { splitBillingDetails, splitingBillingHistory } from '../utils/helpers';

export const getPlansForSubscription = (retinoscan_plan) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/payments/plans/${retinoscan_plan}/subscription/list/`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getPlansForPrepaid = (retinoscan_plan) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/payments/plans/${retinoscan_plan}/prepaid/list/`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const purchasePlan = (id, lang) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/payments/payment/continue/`, {
        template_id: id,
        locale: lang,
      })
      .then((result) => {
        if (result?.data && !result?.data?.link) {
          return;
        }
        resolve(result.data);
      })
      .catch((error) => {
        dispatch({
          type: LOADER,
          payload: true,
        });
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload: 'There was a problem of creating checkout session. Please try again...',
        });
      });
  });
};

export const getBillingDetails = () => (dispatch) => {
  trackPromise(
    axiosInstance
      .get('/payments/billing/summary/')
      .then((result) => {
        dispatch({
          type: GET_BILLING_DETAILS,
          payload: splitBillingDetails(result.data),
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            'There was a problem retrieving your billing details. Please refresh the page to try again.',
        });
      }),
    'billing-area'
  );
};

export const getBillingHistory = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get('/payments/billing/history/')
      .then((result) => {
        resolve(splitingBillingHistory(result.data));
      })
      .catch((error) => {
        dispatch({
          type: SET_SNACKBAR_ERROR,
          payload:
            error.response?.data?.errors?.[0]?.message ??
            'There was a problem retrieving your billing history. Please refresh the page to try again.',
        });
      });
  });
};
