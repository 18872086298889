import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import FadeIn from 'react-fade-in';
import { t } from 'i18next';

import { styled } from '@mui/material/styles';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
} from '@mui/material';
import PropTypes, { any } from 'prop-types';

// LOCAL UI IMPORTS
import SmallButton from '../../../components/layout/atoms/SmallButton';
import InfoToolTip from '../../../components/layout/atoms/InfoToolTip';
import ResultsToolTip from '../../../components/layout/molecules/ResultsToolTip';
import ExamsAccordion from '../../../components/layout/molecules/ExamsAccordion';
import IconHeading from '../../../components/layout/molecules/IconHeading';
import Loading from '../../../components/layout/organisms/Loading/Loading';

// ICON IMPORTS
import { PlusIcon, TrashIcon, UserCheckIcon, XIcon } from '../../../resources/icons';

import { createExam, deleteExambyid } from '../../../actions/exams';
import { setSnackbar } from '../../../actions/controls';
import { getPatientExams } from '../../../actions/patients';
import { convertToCustomDateString, convertToCustomDateStringforDelete } from '../../../utils/date';
import { EXAM_ORDERED, EXAM_PENDING, EXAM_SUBMITTED } from '../../../constants/constants';
import { set } from 'date-fns';
import theme from '../../../styles/theme';
import { backgroundColor, fontWeight, justifyContent } from 'styled-system';
import Modal from '../../../components/layout/templates/Modal';
import { handleModalOpen } from '../../../actions/controls';
import { Warning } from '@mui/icons-material';
import LargeButton from '../../../components/layout/atoms/LargeButton';
import { getBillingDetails } from '../../../actions/subscriptions';

const StyledExamsGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '359px',
  padding: '0 4px',
  overflowY: 'auto',
}));

const StyledIconButton = styled(IconButton)(({ theme, showdelete = false }) => ({
  backgroundColor: showdelete ? theme.palette.error.light : theme.palette.background.dark,
  width: '44px',
  height: '40px',
  borderRadius: '10px',
  color: showdelete ? theme.palette.error.lighter : theme.palette.primary.main,
  '&:hover': {
    color: showdelete ? theme.palette.error.lighter : theme.palette.primary.main,
    backgroundColor: showdelete ? theme.palette.error.light : theme.palette.background.dark,
  },
}));
const StyledButtonGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-start',
  [theme.breakpoints.up('lg')]: {
    justifyContent: 'flex-end',
  },
}));

const PREFIX = 'DeleteExamModel';

const classes = {
  container: `${PREFIX}-container`,
  form: `${PREFIX}-form`,
  formFields: `${PREFIX}-formFields`,
  submit: `${PREFIX}-submit`,
  datePicker: `${PREFIX}-datePicker`,
  switch: `${PREFIX}-switch`,
};
const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.container}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.form}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.formFields}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.submit}`]: {
    width: '30%',
  },

  [`& .${classes.datePicker}`]: {
    margin: '0',
  },

  [`& .${classes.switch}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

// FUNCTIONAL COMPONENT
const PatientExams = (props) => {
  const { patientDetails, exams, createExam, deleteExambyid, getPatientExams, getBillingDetails } = props;
  // ROUTER LINKING
  // To link to another route, use -> props.history.push('/route_name/')
  const history = useHistory();
  const [selectedExam, setSelectedExam] = useState(null);
  const [deleteExamVisible, setDeleteExamVisible] = useState(false);
  const { words: staticWords, styles: lanStyle } = useSelector((state) => state.handlingTranslation);
  const { promiseInProgress } = usePromiseTracker({ area: 'patient-exams-area' });
  const [deleteExam, setDeleteExam] = useState();
  // COMPONENT STATE
  // const [values, setValues] = React.useState({ value: 'value' });

  // FUNCTIONS
  // const handleChange = (event) => {
  //     setValues({ ...values, [event.target.name]: event.target.value });
  // }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = (exam) => {
    setDeleteExam(exam);
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setDeleteExam(null);
    setIsModalOpen(false);
  };

  const handleDelete = (examis) => {
    deleteExambyid(examis)
      .then((result) => {
        getPatientExams(patientDetails.id);
        getBillingDetails();
        setDeleteExamVisible(false);
      })
      .catch((error) => {
        setDeleteExamVisible(false);
        console.log(error);
      });
    // result.then((results) => {
    //   console.log(results);
    // });
    // result.catch((error) => {
    //   console.log(error);
    // });

    handleClose();
  };

  const handleNewExamClick = () => {
    createExam(patientDetails, history);
  };

  const handleExamClick = (event, exam) => {
    event.preventDefault();
    const { id } = exam;
    const { status } = exam.process;

    if (status === EXAM_PENDING) {
      history.push(`/patient/${patientDetails?.id}/create_exam/${id}/`);
    } else if (status === EXAM_SUBMITTED) {
      history.push(`/patient/${patientDetails?.id}/exam_details/${id}/`);
    }
  };

  useEffect(() => {
    if (patientDetails?.exams && exams?.exam_details?.id) {
      const targetExam = patientDetails?.exams?.find((exam) => exam.id === exams.exam_details.id);
      if (
        targetExam?.process?.overall_status !== exams.exam_details.overall_status ||
        targetExam?.process?.status !== exams.exam_details.process?.status
      ) {
        getPatientExams(patientDetails.id);
      }
    }
  }, [exams?.exam_details?.id, patientDetails?.id]);
  useEffect(() => {
    getPatientExams(patientDetails.id);
    if (deleteExamVisible && patientDetails?.exams?.length > 0) {
      setDeleteExamVisible(true);
    } else {
      setDeleteExamVisible(false);
    }
  }, [patientDetails?.exams?.length]);

  // FUNCTIONS END

  // DISPLAY EXAMS
  const renderExamsList = () => {
    return (
      <FadeIn>
        <Grid container mt={2}>
          <Grid item container display="flex" justifyContent="space-between" alignItems="center" xs={12} pr={3} pl={3}>
            <Grid item container xs={9}>
              <Grid item xs={5}>
                <Typography textOverflow="ellipsis" overflow="hidden">
                  {staticWords.Exam_Date}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{staticWords.Practitioner}</Typography>
              </Grid>
            </Grid>
            <Grid item display="flex" alignItems="center" gap={1} xs={3}>
              <ResultsToolTip />
              <Box component="div">
                <Typography>{staticWords.Status}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {patientDetails.exams.map((exam) => {
              return (
                <ExamsAccordion
                  key={exam.id}
                  title={'All Exams'}
                  status={exam.process?.overall_status}
                  operator={exam.operator}
                  dateString={convertToCustomDateString(exam.process?.last_submitted_at, 'dd/MM/yyyy')}
                  selected={selectedExam === exam.id}
                  onMouseDown={() => setSelectedExam(exam.id)}
                  onMouseLeave={() => setSelectedExam(null)}
                  onClick={(event) => handleExamClick(event, exam)}
                  expandable={false}
                />
              );
            })}
          </Grid>
        </Grid>
      </FadeIn>
    );
  };

  const renderDeleteExamsList = () => {
    return (
      <FadeIn>
        <Grid container mt={2}>
          <Grid item container display="flex" justifyContent="space-between" alignItems="center" xs={12} pr={3} pl={3}>
            <Grid item container xs={9}>
              <Grid item xs={5}>
                <Typography textOverflow="ellipsis" overflow="hidden">
                  {staticWords.Exam_Date}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{staticWords.Practitioner}</Typography>
              </Grid>
            </Grid>
            <Grid item display="flex" alignItems="center" gap={1} xs={3}>
              <ResultsToolTip />
              <Box component="div">
                <Typography>{staticWords.Status}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {patientDetails.exams.map((exam) => {
              return (
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={10.5}>
                    <ExamsAccordion
                      disabled={true}
                      key={exam.id}
                      title={'All Exams'}
                      status={exam.process?.overall_status}
                      operator={exam.operator}
                      dateString={convertToCustomDateString(exam.process?.last_submitted_at, 'dd/MM/yyyy')}
                      selected={selectedExam === exam.id}
                      onMouseDown={() => setSelectedExam(exam.id)}
                      onMouseLeave={() => setSelectedExam(null)}
                      onClick={(event) => {}}
                      expandable={false}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <StyledIconButton showdelete={true} aria-label="delete" onClick={() => handleOpen(exam)}>
                      <TrashIcon width={18} height={18} />
                    </StyledIconButton>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </FadeIn>
    );
  };

  const renderCancelButton = () => {
    return (
      <SmallButton
        startIcon={<XIcon />}
        onClick={() => {
          setDeleteExamVisible(false);
        }}
      >
        {staticWords.Cancel}
      </SmallButton>
    );
  };

  const renderDeleteButton = () => {
    return (
      <StyledIconButton
        disableRipple
        aria-label="delete"
        onClick={() => {
          setDeleteExamVisible(true);
        }}
      >
        <TrashIcon width={18} height={18} />
      </StyledIconButton>
    );
  };

  const renderAddExamButton = () => {
    if (patientDetails.exams) {
      return (
        <SmallButton
          onClick={handleNewExamClick}
          startIcon={<PlusIcon />}
          disabled={
            patientDetails?.exams &&
            (patientDetails?.exams[0]?.process?.status === EXAM_PENDING || patientDetails?.exams?.length >= 50)
          }
        >
          {staticWords.Start_New_Exam}
        </SmallButton>
      );
    }
  };
  {
    /* Dialog */
  }
  const DeleteDialogView = ({ isModalOpen, handleClose, handleDelete, exam }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };

    return (
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        sx={{
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <DialogTitle sx={{ marginLeft: theme.spacing(2), marginTop: theme.spacing(2) }}>
          <IconHeading
            title={'Delete this eye exam'}
            icon={<Warning />}
            style_2={{ backgroundColor: theme.palette.error.lighter }}
          />
        </DialogTitle>
        <DialogContent sx={{ marginLeft: theme.spacing(2) }}>
          <Typography sx={(theme.typography.h4, { color: theme.palette.text.grey })}>
            {t('LB_delete_exam_desc')}
          </Typography>
          <Grid
            container
            sm={12}
            alignContent={'center'}
            padding={theme.spacing(2)}
            sx={{
              backgroundColor: theme.palette.background.dark,
              borderRadius: '10px',
              padding: '16px',
              marginTop: '16px',
            }}
          >
            <Grid container sm={12}>
              <Grid item sm={localStorage.getItem('selectedLang') === 'en' ? 3 : 4.5}>
                <Typography
                  padding={theme.spacing(1)}
                  sx={
                    (theme.typography.h6, { fontSize: '16px', color: theme.palette.text.primary, fontWeight: 'bold' })
                  }
                >
                  {t('Exam_Date')}
                </Typography>
              </Grid>
              <Grid item sm={localStorage.getItem('selectedLang') === 'en' ? 9 : 7.5}>
                <Typography
                  padding={theme.spacing(1)}
                  sx={(theme.typography.h6, { fontSize: '16px', fontWeight: 400, color: theme.palette.text.primary })}
                >
                  {convertToCustomDateStringforDelete(exam?.process?.last_submitted_at, 'dd-MM-yyyy, hh:mm:ss')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container sm={12}>
              <Grid item sm={localStorage.getItem('selectedLang') === 'en' ? 3 : 4.5}>
                <Typography
                  padding={theme.spacing(1)}
                  sx={
                    (theme.typography.h6, { fontSize: '16px', color: theme.palette.text.primary, fontWeight: 'bold' })
                  }
                >
                  {t('Patient')}
                </Typography>
              </Grid>
              <Grid item sm={localStorage.getItem('selectedLang') === 'en' ? 9 : 7.5}>
                <Typography
                  padding={theme.spacing(1)}
                  sx={(theme.typography.h6, { fontSize: '16px', fontWeight: 400, color: theme.palette.text.primary })}
                >
                  {patientDetails?.user?.user_contact?.first_name || '-'}{' '}
                  {patientDetails?.user?.user_contact?.last_name || ''} ( {patientDetails?.account_number || '-'} )
                </Typography>
              </Grid>
            </Grid>
            <Grid container sm={12}>
              <Grid item sm={localStorage.getItem('selectedLang') === 'en' ? 3 : 4.5}>
                <Typography
                  padding={theme.spacing(1)}
                  sx={
                    (theme.typography.h6, { fontSize: '16px', color: theme.palette.text.primary, fontWeight: 'bold' })
                  }
                >
                  {t('Practitioner')}
                </Typography>
              </Grid>
              <Grid item sm={localStorage.getItem('selectedLang') === 'en' ? 9 : 7.5}>
                <Typography
                  padding={theme.spacing(1)}
                  textTransform="capitalize"
                  sx={(theme.typography.h6, { fontSize: '16px', fontWeight: 400, color: theme.palette.text.primary })}
                >
                  {exam?.operator?.first_name || '-'}
                  {exam?.operator?.branch && ` (${exam?.operator?.branch})`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} color="primary" />}
            label={t('LB_yes_permently_delete')}
            style={
              (theme.typography.h6,
              { marginTop: '14px', fontSize: '13px', fontWeight: 400, color: theme.palette.text.primary })
            }
          />
          <Grid
            container
            sm={12}
            spacing={2}
            alignContent={'center'}
            padding={theme.spacing(2)}
            sx={{
              borderRadius: '10px',
              padding: '0',
              marginTop: '16px',
            }}
          >
            <Grid container display="flex" justifyContent="space-between">
              <Grid item xs={6}>
                <LargeButton customWidth="27ch" buttonType="secondary" onClick={handleClose}>
                  {t('Cancel')}
                </LargeButton>
              </Grid>
              <Grid item xs={6}>
                <LargeButton
                  type="submit"
                  customWidth="27ch"
                  onClick={() => handleDelete(exam?.id)}
                  color="blue"
                  buttonType={'red'}
                  disabled={!isChecked}
                >
                  {t('LB_delete_exam')}
                </LargeButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Grid container>
      {/* SECTION TITLE */}
      <Grid container alignItems="center" justifyContent="space-between" mb={2}>
        <Grid item>
          {deleteExamVisible ? (
            <IconHeading title={'All Exams - Delete'} icon={<UserCheckIcon />} />
          ) : (
            <IconHeading title={'All Exams'} icon={<UserCheckIcon />} />
          )}
        </Grid>

        <Grid item>
          {deleteExamVisible ? (
            renderCancelButton()
          ) : (
            <StyledButtonGrid container display="flex" item wrap="nowrap" spacing={1} alignItems="center">
              {patientDetails?.exams?.length > 0 ? <Grid item>{renderDeleteButton()}</Grid> : null}
              <Grid item>
                {(patientDetails?.exams && patientDetails?.exams[0]?.process?.status) === EXAM_PENDING ||
                patientDetails?.exams?.length >= 50 ? (
                  <InfoToolTip
                    placement="left"
                    title={staticWords.Unable_to_add_new_exam}
                    content={
                      patientDetails?.exams?.length >= 50
                        ? staticWords.Each_patient_can_only_have_a_maximum_of_50_exams_
                        : staticWords.Please_complete_and_submit_the_most_recent_exam_to_add_new_exam_
                    }
                  >
                    <div>{renderAddExamButton()}</div>
                  </InfoToolTip>
                ) : (
                  renderAddExamButton()
                )}
              </Grid>
            </StyledButtonGrid>
          )}
        </Grid>

        {/* EXAM LISTS */}
        <StyledExamsGrid>
          {promiseInProgress ? (
            <Grid mt={12}>
              <Loading area="patient-exams-area" />
            </Grid>
          ) : patientDetails?.exams === undefined ? null : patientDetails?.exams?.length ? (
            deleteExamVisible ? (
              renderDeleteExamsList()
            ) : (
              renderExamsList()
            )
          ) : (
            <Typography variant="subtitle1" align="center" mt={12}>
              {staticWords.This_patient_does_not_have_any_existing_exams_}
            </Typography>
          )}
        </StyledExamsGrid>

        <DeleteDialogView
          isModalOpen={isModalOpen}
          handleClose={handleClose}
          handleDelete={handleDelete}
          exam={deleteExam} // Pass selected exam
        />
      </Grid>
    </Grid>
  );
};

PatientExams.propTypes = {
  patientDetails: PropTypes.object,
  exams: PropTypes.object,
  createExam: PropTypes.func,
  getPatientExams: PropTypes.func,
};

// MAP REDUX STATE TO COMPONENT PROPS
// To retrieve patients from props, use -> props.patients
const mapStateToProps = (state) => ({
  patientDetails: state.patients.patientDetails,
  exams: state.exams,
});

export default connect(mapStateToProps, {
  createExam,
  deleteExambyid,
  setSnackbar,
  getPatientExams,
  getBillingDetails,
})(PatientExams);
