export { ReactComponent as ActivityIcon } from './files/activity.svg';
export { ReactComponent as AlertCircleIcon } from './files/alert-circle.svg';
export { ReactComponent as AlertTriangleIcon } from './files/alert-triangle.svg';
export { ReactComponent as ArrowLeftIcon } from './files/arrow-left.svg';
export { ReactComponent as ArrowRightIcon } from './files/arrow-right.svg';
export { ReactComponent as BarChartIcon } from './files/bar-chart.svg';
export { ReactComponent as BasicTierIcon } from './files/basic-subscription.svg';
export { ReactComponent as BasicPlusTierIcon } from './files/basic-plus-subscription.svg';
export { ReactComponent as BlueCheckIcon } from './files/blue_check.svg';
export { ReactComponent as CEIcon } from './files/ce-mark.svg';
export { ReactComponent as CE0197Icon } from './files/ce-0197.svg';
export { ReactComponent as CheckIcon } from './files/check.svg';
export { ReactComponent as CheckSquareIcon } from './files/check-square.svg';
export { ReactComponent as ChevronDownIcon } from './files/chevron-down.svg';
export { ReactComponent as ChevronLeftIcon } from './files/chevron-left.svg';
export { ReactComponent as ChevronRightIcon } from './files/chevron-right.svg';
export { ReactComponent as CircleCollapse } from './files/circle-collapse.svg';
export { ReactComponent as CircleCheckIcon } from './files/circle-check.svg';
export { ReactComponent as CircleExpand } from './files/circle-expand.svg';
export { ReactComponent as CircleXIcon } from './files/circle-xmark.svg';
export { ReactComponent as ClipboardIcon } from './files/clipboard.svg';
export { ReactComponent as ClockIcon } from './files/clock.svg';
export { ReactComponent as CopyIcon } from './files/copy.svg';
export { ReactComponent as CornerRightUpIcon } from './files/corner-right-up.svg';
export { ReactComponent as DashboardIcon } from './files/dashboard.svg';
export { ReactComponent as DollarSignIcon } from './files/dollar-sign.svg';
export { ReactComponent as DownloadCloudIcon } from './files/download-cloud.svg';
export { ReactComponent as DownloadIcon } from './files/download.svg';
export { ReactComponent as ECREPIcon } from './files/ec-rep.svg';
export { ReactComponent as EditIcon } from './files/edit.svg';
export { ReactComponent as ExportRetinoScanIcon } from './files/exportRetinoScanIcon.svg';
export { ReactComponent as ExportRetinoScanPlusIcon } from './files/exportRetinoScanPlusIcon.svg';
export { ReactComponent as EyeIcon } from './files/eye.svg';
export { ReactComponent as femaleAvatar } from './files/female-avatar.svg';
export { ReactComponent as FileTextIcon } from './files/file-text.svg';
export { ReactComponent as FrownIcon } from './files/frown.svg';
export { ReactComponent as GlobeIcon } from './files/globe.svg';
export { ReactComponent as WhiteGlobeIcon } from './files/white-globe.svg';
export { ReactComponent as GreenBGCheck } from './files/green-bg-check.svg';
export { ReactComponent as GreenCheckIcon } from './files/green_check.svg';
export { ReactComponent as GreyXIcon } from './files/grey_x.svg';
export { ReactComponent as HashIcon } from './files/hash-mark.svg';
export { ReactComponent as HeartIcon } from './files/heart.svg';
export { ReactComponent as HelpCircleIcon } from './files/help-circle.svg';
export { ReactComponent as ImageIcon } from './files/image.svg';
export { ReactComponent as InfoIcon } from './files/info.svg';
export { ReactComponent as LinkIcon } from './files/link.svg';
export { ReactComponent as LockIcon } from './files/lock-icon.svg';
export { ReactComponent as MailIcon } from './files/mail.svg';
export { ReactComponent as maleAvatar } from './files/male-avatar.svg';
export { ReactComponent as ManufacturerIcon } from './files/manufacturer.svg';
export { ReactComponent as ManufacturerAUIcon } from './files/manufacturer-au.svg';
export { ReactComponent as ManufacturerSGIcon } from './files/manufacturer-sg.svg';
export { ReactComponent as MedicalDeviceIcon } from './files/md-mark.svg';
export { ReactComponent as MenuDotsIcon } from './files/menu-dots.svg';
export { ReactComponent as MenuIcon } from './files/menu.svg';
export { ReactComponent as MessageSquareIcon } from './files/message-square.svg';
export { ReactComponent as MinusSquareFilledIcon } from './files/minus-square-filled.svg';
export { ReactComponent as NoPurchaseHistory } from './files/no-purchase-history.svg';
export { ReactComponent as NoteIcon } from './files/note.svg';
export { ReactComponent as otherAvatar } from './files/other-avatar.svg';
export { ReactComponent as PendingReview } from './files/pending-review.svg';
export { ReactComponent as PhoneCallIcon } from './files/phone-call.svg';
export { ReactComponent as PlusIcon } from './files/plus.svg';
export { ReactComponent as PlusSquareIcon } from './files/plus-square.svg';
export { ReactComponent as PlusSquareFilledIcon } from './files/plus-square-filled.svg';
export { ReactComponent as PremiumTierIcon } from './files/premium-subscription.svg';
export { ReactComponent as PurchaseSummary } from './files/purchase-summary.svg';
export { ReactComponent as RedXIcon } from './files/red_x.svg';
export { ReactComponent as RefIcon } from './files/ref-mark.svg';
export { ReactComponent as RetinoScanLogo } from './files/retinoscan.svg';
export { ReactComponent as RetinoScanPlusLogo } from './files/retinoscan-plus.svg';
export { ReactComponent as SearchIcon } from './files/search.svg';
export { ReactComponent as SettingsIcon } from './files/settings.svg';
export { ReactComponent as ShoppingBag } from './files/shopping.svg';
export { ReactComponent as ShoppingBagPrimary } from './files/shopping-bag-primary.svg';
export { ReactComponent as SmileIcon } from './files/smile.svg';
export { ReactComponent as SortAscendingIcon } from './files/sort-ascending.svg';
export { ReactComponent as SortDescendingIcon } from './files/sort-descending.svg';
export { ReactComponent as StandardTierIcon } from './files/standard-subscription.svg';
export { ReactComponent as StandardPlusTierIcon } from './files/standard-plus-subscription.svg';
export { ReactComponent as SaveIcon } from './files/save.svg';

export { ReactComponent as TimerIcon } from './files/timer.svg';
export { ReactComponent as TranslateIcon } from './files/translate.svg';
export { ReactComponent as TrashIcon } from './files/trash.svg';
export { ReactComponent as UDIIcon } from './files/udi-mark.svg';
export { ReactComponent as UploadCloudIcon } from './files/upload-cloud.svg';
export { ReactComponent as UserCheckIcon } from './files/user-check.svg';
export { ReactComponent as UserPlusIcon } from './files/user-plus.svg';
export { ReactComponent as UserIcon } from './files/user.svg';
export { ReactComponent as UsersIcon } from './files/users.svg';
export { ReactComponent as VectorIcon } from './files/vector.svg';
export { ReactComponent as VideoIcon } from './files/video.svg';
export { ReactComponent as XIcon } from './files/x.svg';
export { ReactComponent as LITE } from './files/LITE.svg';
export { ReactComponent as FULL } from './files/FULL.svg';
export { ReactComponent as TeleConsultLogo } from './files/teleConsult.svg';
